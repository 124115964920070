/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import moment from "moment";

import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
    Dialog,
  Checkbox,
  Typography,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from '@material-ui/core/Chip';
import DateFnsUtils from "@date-io/date-fns";
import viLocale from "date-fns/locale/vi";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

import * as appActions from "../../../../core/app.store";
import * as planningAction from "../../../../redux/store/planning/planning.store";
import * as provinceAction from "../../../../redux/store/province-management/province.store";
import * as planning_categoryAction from "../../../../redux/store/planning-category-management/planning-category.store";
import * as governmentAction from "../../../../redux/store/government-management/government.store";
import * as updateFrequencyAction from "../../../../redux/store/update-frequency-management/update-frequency.store";
import * as projectStatusAction from "../../../../redux/store/project-status-management/project-status.store";
import FileInputComponent from "./../../../../components/file-input/file-input.view";
import * as configCommon from "./../../../../common/config";
import FormAutocomplete from "./../../../../components/form-input/autocomplete.view";

import {
  NotificationMessageType,
  changeAlias,
  APIUrlDefault,
} from "../../../../utils/configuration";
import * as viVN from "../../../../language/vi-VN.json";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import FileManagement from "../../../../components/file_management/file_management";

function AddRecordsManagement(props) {
  const classes = useStyles();
  const { isOpen, onClose, onSuccess, showLoading, cgisId } = props;

    const today = new Date();
    const nowDate = new Date(
        today.getUTCFullYear(),
        today.getUTCMonth(),
        today.getDate(),
        0,
        0,
        0,
        0
    );

    const [expiredDate, setExpiredDate] = useState(nowDate);
  //const [planningLookUpModel, setPlanningLookUpModel] = useState(null);
    const [planningStatusModel, setPlanningStatusModel] = useState([]);
    const [planningStatus, setPlanningStatus] = useState("");

    const [planningLevelModel, setPlanningLevelModel] = useState([]);
    const [planningLevel, setPlanningLevel] = useState("");

    const [planningTypeModel, setPlanningTypeModel] = useState([]);
    const [planningType, setPlanningType] = useState("");

    const [lookupTag, setLookupTag] = useState([]);

  const [documentTypeModel, setDocumentTypeModel] = useState([]);
  const [approvalAgencyModel, setApprovalAgencyModel] = useState([]);
    const [lookupProvinceModel, setLookupProvinceModel] = useState([]);
    const [lookupPlanningCategoryModel, setlookupPlanningCategoryModel] = useState(null);
    const [lookupGovernmentModel, setLookupGovernmentModel] = useState(null);
    const [government, setGovernment] = useState("");

    const [lookupUpdateFrequencyModel, setLookupUpdateFrequencyModel] = useState(null);
    const [updateFrequency, setUpdateFrequency] = useState("");

    const [lookupProjectStatusModel, setLookupProjectStatusModel] = useState([]);
    const [projectStatus, setProjectStatus] = useState("");

    const [planningCategory, setPlanningCategory] = useState("");
  const [documentType, setDocumentType] = useState("");
    const [approvalAgency, setApprovalAgency] = useState("");
    const [lookupProvince, setLookupProvince] = useState(null);
    //const [lookupPlanningCategory, setlookupPlanningCategory] = useState(null);

  const [avatar, setAvatar] = useState(null);
  const [recordsManagementData, setRecordsManagementData] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const txtPlanningCode = document.getElementById("txtPlanningCode");
  const [isExistPlanningCode, setIsExistPlanningCode] = useState(false);

  const [files, setFiles] = useState([]);
  const [filesTemp, setFilesTemp] = useState([]);
  const [isShow, setShow] = useState(false);
  const [tifFiles, setTifFiles] = useState([]);
  const [tifFilesTemp, setTifFilesTemp] = useState([]);
  const [isShowTif, setShowTif] = useState(false);
  const [zipFiles, setZipFiles] = useState([]);
  const [zipFilesTemp, setZipFilesTemp] = useState([]);
  const [isShowZip, setShowZip] = useState(false);
  const [tifName, setTifName] = useState(null);
    const [zipName, setZipName] = useState(null);
    const [isActive, setIsActive] = useState(true);

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    clearErrors,
    setError,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    onGetData();
  }, []);

    const onSetExpiredDate = (time) => {
        setExpiredDate(time);
    };
  const onGetData = () => {
    showLoading(true);
    Promise.all([
      onGetLookUpPlanningStatus(),
      onGetLookUpPlanningLevel(),
      onGetLookUpPlanningType(),
      onGetLookUpDocumentType(),
      getApprovalAgencyLevel(),
        getLookupProvince(),
        getLookupPlanningCategory(),
        getLookupGovernment(),
        getLookupUpdateFrequency(),
        getLookupProjectStatus()
    ])
        .then((res) => {
        const [
          status,
          level,
          type,
          documentType,
          approvalAgency,
            lookupProvince,
            lookupPlanningCategory,
            gvn,
            updFreq,
          proStt,
            ] = res;

        //planningLookUp &&
        //  planningLookUp.content &&
        //  setPlanningLookUpModel(planningLookUp.content);
        status && status.content && setPlanningStatusModel(status.content);
        level && level.content && setPlanningLevelModel(level.content);
        type && type.content && setPlanningTypeModel(type.content);
        documentType &&
          documentType.content &&
          setDocumentTypeModel(documentType.content);
        approvalAgency &&
          approvalAgency.content &&
          setApprovalAgencyModel(approvalAgency.content);
        lookupProvince &&
          lookupProvince.content &&
              setLookupProvinceModel(lookupProvince.content);
          lookupPlanningCategory &&
              lookupPlanningCategory.content &&
              setlookupPlanningCategoryModel(lookupPlanningCategory.content);
          gvn && gvn.content && setLookupGovernmentModel(gvn.content);
          updFreq && updFreq.content && setLookupUpdateFrequencyModel(updFreq.content);
          proStt && proStt.content && setLookupProjectStatusModel(proStt.content);
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

    const handleChangeStatus = (event) => {
        event.persist();
        setIsActive(event.target.checked);
    }

  const onGetLookUpPlanning = () => {
    return new Promise((resolve, reject) => {
      planningAction.GetLookUpPlanning().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  const onGetLookUpPlanningStatus = () => {
    return new Promise((resolve, reject) => {
      planningAction.GetLookUpPlanningStatus().then(
          (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  const onGetLookUpPlanningLevel = () => {
    return new Promise((resolve, reject) => {
      planningAction.GetLookUpPlanningLevel().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  const getApprovalAgencyLevel = () => {
    return new Promise((resolve, reject) => {
      planningAction.ApprovalAgencyLevel().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  const getLookupProvince = () => {
    return new Promise((resolve, reject) => {
      provinceAction.GetLookupProvince().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
    };

    const getLookupPlanningCategory = () => {
        return new Promise((resolve, reject) => {
            planning_categoryAction.GetLookup().then(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                }
            );
        });
    };

    const getLookupGovernment = () => {
        console.log("get Lookup Planning Category");
        return new Promise((resolve, reject) => {
            governmentAction.GetLookup().then(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                }
            );
        });
    };

    const getLookupUpdateFrequency = () => {
        return new Promise((resolve, reject) => {
            updateFrequencyAction.GetLookup().then(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                }
            );
        });
    };
    const getLookupProjectStatus = () => {
        return new Promise((resolve, reject) => {
            projectStatusAction.GetLookup().then(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                }
            );
        });
    };
  const onGetLookUpPlanningType = () => {
    return new Promise((resolve, reject) => {
      planningAction.GetLookUpPlanningType().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  const onGetLookUpDocumentType = () => {
    return new Promise((resolve, reject) => {
      planningAction.GetLookUpDocumentType().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

    const onConfirm = (data) => {
    if (!data) {
      return;
    } else if (isExistPlanningCode) {
      if (txtPlanningCode) txtPlanningCode.focus();
      return;
    } else {
        setRecordsManagementData(data);
        //onAddData(false);
      setOpenConfirmDialog(true);
    }
  };

    const onAddData = (data) => {

    if (isExistPlanningCode) {
      if (txtPlanningCode) txtPlanningCode.focus();
      return;
    }

    showLoading(true);

      let params = {
          CategoryId: planningCategory.id || null,
          GovernmentId: government.id || null,
          UpdateFrequencyId: updateFrequency.id || null,
          Name: data.planningName || null,
          PlanningCode: data.planningCode || null,
      PlanningTypeId: planningType.id || null,
      //PlanningTypeId:1,
          Place: data.place || null,
          Order: parseInt(data.order) || null,
          PlanningAgency: data.planningAgency || null,
          ConsultingUnit: data.consultingUnit || null,
          Investor: data.investor || null,
      PlanningStatusId: planningStatus.id || null,
          AgencySubmitted: data.agencySubmitted || null,
          PlanningUnit: data.planningUnit || null,
      PlanningLevelId: planningLevel.id || null,
          Population: parseInt(data.population) || null,
          Acreage: data.acreage || null,
          LandForConstruction: data.landForConstruction || null,
          Report: data.report || null,
          Note: data.note || null,
      DocumentTypeId: documentType.id || null,
      ApprovalAgencyLevelId: approvalAgency.id || null,
          PlanningProvinceIds:
        (lookupProvince &&
          lookupProvince.length > 0 &&
          lookupProvince.map((item) => {
            return item.id;
          })) ||
        null,
          isOldPlan: false,

          expiredDate:
              (expiredDate && moment(expiredDate).format("YYYY-MM-DDTHH:mm:ssZ")) || null,
          projectStatusId: projectStatus.id || null,
          isActive: isActive
    };
        
    if (files && files.length > 0) {
      params = {
        ...params,
        DocumentUploadId: files && files[0].fileId,
      };
    }

    if (tifFiles && tifFiles.length > 0) {
      params = {
        ...params,
        tifFile: tifFiles[0].fileId,
      };
    }

    if (zipFiles && zipFiles.length > 0) {
      params = {
        ...params,
        shpFile: zipFiles[0].fileId,
      };
    }

    planningAction.CreatePlanning(params).then(
      (res) => {
        showLoading(false);
        onSuccess();
      },
      (err) => {
        showLoading(false);
        ShowNotification(
          viVN.Errors[(err && err.errorType) || "UnableHandleException"],
          NotificationMessageType.Error
        );
      }
    ).catch((err) => {
      showLoading(false);
      ShowNotification(
        viVN.Errors[(err && err.errorType) || "UnableHandleException"],
        NotificationMessageType.Error
      );
    });
  };

  let timeout = 0;
  const handleCheckCodeExist = (event) => {
    let value = event;

    if (!value) {
      setError("planningCode", { type: "required" });
    } else {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        CheckExistedPlanning(value);
      }, 100);
    }
  };

  const CheckExistedPlanning = (planningCode) => {
    planningAction
      .CheckExistedPlanning(planningCode)
      .then((res) => {
        if (res && res.content && res.content.status) {
          clearErrors("planningCode");
          setIsExistPlanningCode(false);
        } else {
          setError("planningCode", { type: "validate" });
          setIsExistPlanningCode(true);
        }
      })
      .catch((err) => {
        setError("planningCode", { type: "validate" });
          setIsExistPlanningCode(true);
      });
  };

  const onOpenSelectTifFile = () => {
    setShowTif(true);
    setTifFilesTemp(tifFiles);
  }

  const onCloseSelectTifFile = () => {
    setShowTif(false);
    setTifFiles(tifFilesTemp);
  }

  const onSaveSelectTifFile = () => {
    setShowTif(false);
    setTifName(tifFiles[0].fileName);
  };

  const onOpenSelectZipFile = () => {
    setShowZip(true);
    setZipFilesTemp(zipFiles);
    setZipName(null);
  }

  const onCloseSelectZipFile = () => {
    setShowZip(false);
    setZipFiles(zipFilesTemp);
  }

  const onSaveSelectZipFile = () => {
    setShowZip(false);
    setZipName(zipFiles[0].fileName);
  };

  const onOpenSelectFile = () => {
    setShow(true);
    setFilesTemp(files);
  };

  const onCloseSelectFile = () => {
    setShow(false);
    setFiles(filesTemp);
  };

  const onSaveSelectFile = () => {
    setShow(false);
  };

  return (
    <div>
      {isOpen && (
        <Dialog
          open={isOpen}
          onClose={onClose}
          fullWidth={true}
          maxWidth="md"
          scroll="paper"
        >
          <DialogTitle disableTypography className="border-bottom">
            <Typography variant="h6">Thêm dự án quy hoạch</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

                  <form onSubmit={handleSubmit(onAddData)} >
                      <DialogContent className="pt-4 pb-2" dividers>

                          <div className="form-group row">
                              <div className="col-6">
                                  <label className="text-dark">
                                      Mã quy hoạch<span className="required"></span>
                                  </label>

                                  <TextField
                                      fullWidth
                                      type="text"
                                      name="planningCode"
                                      inputRef={register({
                                          required: true,
                                          maxLength: 50,
                                          validate: handleCheckCodeExist,
                                      })}
                                      onChange={(e) => {
                                          setValue("planningCode", changeAlias(e.target.value), {
                                              shouldDirty: true,
                                          });
                                      }}
                                      id="txtPlanningCode"
                                      error={
                                          (errors.planningCode &&
                                              errors.planningCode.type === "required") ||
                                          (errors.planningCode &&
                                              errors.planningCode.type === "maxLength") ||
                                          (errors.planningCode &&
                                              errors.planningCode.type === "validate")
                                      }
                                      variant="outlined"
                                      size="small"
                                  />
                                  {errors.planningCode &&
                                      errors.planningCode.type === "required" && (
                                          <span className="error">Trường này là bắt buộc</span>
                                      )}
                                  {errors.planningCode &&
                                      errors.planningCode.type === "maxLength" && (
                                          <span className="error">Tối đa 50 ký tự</span>
                                      )}
                                  {errors.planningCode &&
                                      errors.planningCode.type === "validate" && (
                                          <span className="error">Mã quy hoạch đã tồn tại</span>
                                      )}
                              </div>

                              <div className="col-6">
                                  <label className="text-dark">
                                      Tên đồ án QH<span className="required"></span>
                                  </label>

                                  <TextField
                                      fullWidth
                                      inputRef={register({ required: true })}
                                      type="text"
                                      name="planningName"
                                      error={
                                          errors.planningName &&
                                          errors.planningName.type === "required"
                                      }
                                      variant="outlined"
                                      size="small"
                                  />
                                  {errors.planningName &&
                                      errors.planningName.type === "required" && (
                                          <span className="error">Trường này là bắt buộc</span>
                                      )}
                              </div>
                          </div>

                          <div className="form-group row">
                              {planningTypeModel && planningTypeModel.length > 0 && (
                                  <div className="col-6">
                                      <label className="text-dark">
                                          Kiểu quy hoạch<span className="required"></span>
                                      </label>

                                      <Autocomplete
                                          options={planningTypeModel}
                                          getOptionLabel={(option) =>
                                              typeof option === "string" ? option : option.name
                                          }
                                          value={planningType}
                                          onChange={(event, newValue) => {
                                              setPlanningType(newValue);
                                          }}
                                          disableClearable={true}
                                          renderInput={(params) => (
                                              <TextField
                                                  {...params}
                                                  name="planningTypeId"
                                                  inputRef={register({ required: true })}
                                                  error={
                                                      errors.planningTypeId &&
                                                      errors.planningTypeId.type === "required"
                                                  }
                                                  variant="outlined"
                                                  size="small"
                                              />
                                          )}
                                      />
                                      {errors.planningTypeId &&
                                          errors.planningTypeId.type === "required" && (
                                              <span className="error">Trường này là bắt buộc</span>
                                          )}
                                  </div>
                              )}
                              {
                                  lookupPlanningCategoryModel && lookupPlanningCategoryModel.length > 0 && (
                                  <div className="col-6">
                                      <label className="text-dark">
                                          Loại quy hoạch<span className="required"></span>
                                      </label>

                                      <Autocomplete
                                          options={lookupPlanningCategoryModel}
                                          getOptionLabel={(option) =>
                                              typeof option === "string" ? option : option.name
                                          }
                                          value={planningCategory}
                                          onChange={
                                              (event, newValue) => {
                                              setPlanningCategory(newValue);
                                              }
                                          }
                                          disableClearable={true}
                                          renderInput={(params) => (
                                              <TextField
                                                  {...params}
                                                  name="planningCategoryId"
                                                  inputRef={register({ required: true })}
                                                  error={
                                                      errors.planningCategoryId &&
                                                      errors.planningCategoryId.type === "required"
                                                  }
                                                  variant="outlined"
                                                  size="small"
                                              />
                                          )}
                                      />
                                      {errors.planningCategoryId &&
                                          errors.planningCategoryId.type === "required" && (
                                              <span className="error">Trường này là bắt buộc</span>
                                          )}
                                  </div>
                                  )
                              }
                          </div>

                          <div className="form-group row">
                              {lookupGovernmentModel && lookupGovernmentModel.length > 0 && (
                                  <div className="col-6">
                                      <label className="text-dark">
                                          Cơ quan ban hành<span className="required"></span>
                                      </label>

                                      <Autocomplete
                                          options={lookupGovernmentModel}
                                          getOptionLabel={(option) =>
                                              typeof option === "string" ? option : option.name
                                          }
                                          value={government}
                                          onChange={(event, newValue) => {
                                              setGovernment(newValue);
                                          }}
                                          disableClearable={true}
                                          renderInput={(params) => (
                                              <TextField
                                                  {...params}
                                                  name="governmentId"
                                                  inputRef={register({ required: true })}
                                                  error={
                                                      errors.governmentId &&
                                                      errors.governmentId.type === "required"
                                                  }
                                                  variant="outlined"
                                                  size="small"
                                              />
                                          )}
                                      />
                                      {errors.governmentId &&
                                          errors.governmentId.type === "required" && (
                                              <span className="error">Trường này là bắt buộc</span>
                                          )}
                                  </div>
                              )}
                              {
                                  lookupUpdateFrequencyModel && lookupUpdateFrequencyModel.length > 0 && (
                                  <div className="col-6">
                                      <label className="text-dark">
                                          Tần suất cập nhật<span className="required"></span>
                                      </label>

                                      <Autocomplete
                                              options={lookupUpdateFrequencyModel}
                                          getOptionLabel={(option) =>
                                              typeof option === "string" ? option : option.name
                                          }
                                          value={updateFrequency}
                                          onChange={(event, newValue) => {
                                              setUpdateFrequency(newValue);
                                          }}
                                          disableClearable={true}
                                          renderInput={(params) => (
                                              <TextField
                                                  {...params}
                                                  name="updateFrequencyId"
                                                  inputRef={register({ required: true })}
                                                  error={
                                                      errors.updateFrequencyId&&
                                                      errors.updateFrequencyId.type === "required"
                                                  }
                                                  variant="outlined"
                                                  size="small"
                                              />
                                          )}
                                      />
                                          {errors.updateFrequencyId &&
                                              errors.updateFrequencyId.type === "required" && (
                                              <span className="error">Trường này là bắt buộc</span>
                                          )}
                                  </div>
                              )}
                          </div>
                          <div className="form-group row">
                              <div className="col-6">
                                  <label className="text-dark">
                                      Địa điểm<span className="required"></span>
                                  </label>

                                  <TextField
                                      fullWidth
                                      inputRef={register({ required: true })}
                                      type="text"
                                      name="place"
                                      error={errors.place && errors.place.type === "required"}
                                      variant="outlined"
                                      size="small"
                                  />
                                  {errors.place && errors.place.type === "required" && (
                                      <span className="error">Trường này là bắt buộc</span>
                                  )}
                              </div>
                              <div className="col-6">
                                  <label className="text-dark">
                                      Thứ tự<span className="required"></span>
                                  </label>

                                  <TextField
                                      fullWidth
                                      inputRef={register({ required: true })}
                                      type="text"
                                      name="order"
                                      onChange={(e) =>
                                          setValue("order", e.target.value.replace(/[^0-9]/g, ""))
                                      }
                                      error={errors.order && errors.order.type === "required"}
                                      variant="outlined"
                                      size="small"
                                  />
                                  {errors.order && errors.order.type === "required" && (
                                      <span className="error">Trường này là bắt buộc</span>
                                  )}
                              </div>
                          </div>

                          <div className="form-group row">
                              {planningLevelModel && planningLevelModel.length > 0 && (
                                  <div className="col-6">
                                      <label className="text-dark">
                                          Cấp quy hoạch<span className="required"></span>
                                      </label>

                                      <Autocomplete
                                          options={planningLevelModel}
                                          getOptionLabel={(option) =>
                                              typeof option === "string" ? option : option.name
                                          }
                                          value={planningLevel}
                                          onChange={(event, newValue) => {
                                              setPlanningLevel(newValue);
                                          }}
                                          disableClearable={true}
                                          renderInput={(params) => (
                                              <TextField
                                                  {...params}
                                                  name="planningLevelId"
                                                  inputRef={register({ required: true })}
                                                  error={
                                                      errors.planningLevelId &&
                                                      errors.planningLevelId.type === "required"
                                                  }
                                                  variant="outlined"
                                                  size="small"
                                              />
                                          )}
                                      />
                                      {errors.planningLevelId &&
                                          errors.planningLevelId.type === "required" && (
                                              <span className="error">Trường này là bắt buộc</span>
                                          )}
                                  </div>
                              )}
                              <div className="col-6">
                                  <label className="text-dark">
                                      Cơ quan phê duyệt
                    <span className="required"></span>
                                  </label>

                                  <TextField
                                      fullWidth
                                      inputRef={register({ required: true })}
                                      type="text"
                                      name="planningAgency"
                                      error={
                                          errors.planningAgency &&
                                          errors.planningAgency.type === "required"
                                      }
                                      variant="outlined"
                                      size="small"
                                  />
                                  {errors.planningAgency &&
                                      errors.planningAgency.type === "required" && (
                                          <span className="error">Trường này là bắt buộc</span>
                                      )}
                              </div>

                          </div>

                          <div className="form-group row">
                              <div className="col-6">
                                  <label className="text-dark">
                                      Đơn vị lập quy hoạch<span className="required"></span>
                                  </label>

                                  <TextField
                                      fullWidth
                                      inputRef={register({ required: true })}
                                      type="text"
                                      name="planningUnit"
                                      error={
                                          errors.planningUnit &&
                                          errors.planningUnit.type === "required"
                                      }
                                      variant="outlined"
                                      size="small"
                                  />
                                  {errors.planningUnit &&
                                      errors.planningUnit.type === "required" && (
                                          <span className="error">Trường này là bắt buộc</span>
                                      )}
                              </div>
                              <div className="col-6">
                                  <label className="text-dark">
                                      Cơ quan trình<span className="required"></span>
                                  </label>

                                  <TextField
                                      fullWidth
                                      inputRef={register({ required: true })}
                                      type="text"
                                      name="agencySubmitted"
                                      error={
                                          errors.agencySubmitted &&
                                          errors.agencySubmitted.type === "required"
                                      }
                                      variant="outlined"
                                      size="small"
                                  />
                                  {errors.agencySubmitted &&
                                      errors.agencySubmitted.type === "required" && (
                                          <span className="error">Trường này là bắt buộc</span>
                                      )}
                              </div>

                              
                          </div>

                          <div className="form-group row">
                              <div className="col-6">
                                  <label className="text-dark">
                                      Đơn vị tư vấn<span className="required"></span>
                                  </label>

                                  <TextField
                                      fullWidth
                                      inputRef={register({ required: true })}
                                      type="text"
                                      name="consultingUnit"
                                      error={
                                          errors.consultingUnit &&
                                          errors.consultingUnit.type === "required"
                                      }
                                      variant="outlined"
                                      size="small"
                                  />
                                  {errors.consultingUnit &&
                                      errors.consultingUnit.type === "required" && (
                                          <span className="error">Trường này là bắt buộc</span>
                                      )}
                              </div>
                              <div className="col-6">
                                  <label className="text-dark">
                                      Chủ đầu tư<span className="required"></span>
                                  </label>

                                  <TextField
                                      fullWidth
                                      inputRef={register({ required: true })}
                                      type="text"
                                      name="investor"
                                      error={
                                          errors.investor && errors.investor.type === "required"
                                      }
                                      variant="outlined"
                                      size="small"
                                  />
                                  {errors.investor && errors.investor.type === "required" && (
                                      <span className="error">Trường này là bắt buộc</span>
                                  )}
                              </div>
                          </div>

                          <div className="form-group row">
                              <div className="col-6">
                                  <label className="text-dark">Quyết định phê duyệt</label>

                                  <TextField
                                      fullWidth
                                      type="text"
                                      name="report"
                                      variant="outlined"
                                      size="small"
                                      inputRef={register}
                                  />
                              </div>
                              {documentTypeModel && documentTypeModel.length > 0 && (
                                  <div className="col-6">
                                      <label className="text-dark">
                                          Loại hồ sơ<span className="required"></span>
                                      </label>

                                      <Autocomplete
                                          options={documentTypeModel}
                                          getOptionLabel={(option) =>
                                              typeof option === "string" ? option : option.name
                                          }
                                          value={documentType}
                                          onChange={(event, newValue) => {
                                              setDocumentType(newValue);
                                          }}
                                          disableClearable={true}
                                          renderInput={(params) => (
                                              <TextField
                                                  {...params}
                                                  name="documentTypeId"
                                                  inputRef={register({ required: true })}
                                                  error={
                                                      errors.documentTypeId &&
                                                      errors.documentTypeId.type === "required"
                                                  }
                                                  variant="outlined"
                                                  size="small"
                                              />
                                          )}
                                      />
                                      {errors.documentTypeId &&
                                          errors.documentTypeId.type === "required" && (
                                              <span className="error">Trường này là bắt buộc</span>
                                          )}
                                  </div>
                              )}
                          </div>

                          <div className="form-group row">
                              <div className="col-6">
                                  <label className="text-dark">
                                      Dân số (người)<span className="required"></span>
                                  </label>

                                  <TextField
                                      fullWidth
                                      inputRef={register({ required: true })}
                                      type="text"
                                      name="population"
                                      onChange={(e) =>
                                          setValue(
                                              "population",
                                              e.target.value.replace(/[^0-9]/g, "")
                                          )
                                      }
                                      error={
                                          errors.population && errors.population.type === "required"
                                      }
                                      variant="outlined"
                                      size="small"
                                  />
                                  {errors.population &&
                                      errors.population.type === "required" && (
                                          <span className="error">Trường này là bắt buộc</span>
                                      )}
                              </div>
                              <div className="col-6">
                                  <label className="text-dark">
                                      Diện tích (ha)<span className="required"></span>
                                  </label>

                                  <TextField
                                      fullWidth
                                      inputRef={register({
                                          required: true,
                                          pattern: /^\d+(\.\d{1,9}?|,\d{1,9})?$/,
                                      })}
                                      type="text"
                                      name="acreage"
                                      error={
                                          errors.acreage &&
                                          (errors.acreage.type === "required" ||
                                              errors.acreage.type === "pattern")
                                      }
                                      variant="outlined"
                                      size="small"
                                  />
                                  {errors.acreage && errors.acreage.type === "required" && (
                                      <span className="error">Trường này là bắt buộc</span>
                                  )}
                                  {errors.acreage && errors.acreage.type === "pattern" && (
                                      <span className="error">
                                          Chỉ có thể nhập số hoặc số thập phân
                                      </span>
                                  )}
                              </div>

                              
                          </div>

                          <div className="form-group row">
                              <div className="col-6">
                                  <label className="text-dark">
                                      Đất xây dựng (ha)<span className="required"></span>
                                  </label>

                                  <TextField
                                      fullWidth
                                      inputRef={register({
                                          required: true,
                                          pattern: /^\d+(\.\d{1,9}?|,\d{1,9})?$/,
                                      })}
                                      type="text"
                                      name="landForConstruction"
                                      error={
                                          errors.landForConstruction &&
                                          (errors.landForConstruction.type === "required" ||
                                              errors.landForConstruction.type === "pattern")
                                      }
                                      variant="outlined"
                                      size="small"
                                  />
                                  {errors.landForConstruction &&
                                      errors.landForConstruction.type === "required" && (
                                          <span className="error">Trường này là bắt buộc</span>
                                      )}
                                  {errors.landForConstruction &&
                                      errors.landForConstruction.type === "pattern" && (
                                          <span className="error">
                                              Chỉ có thể nhập số hoặc số thập phân
                                          </span>
                                      )}
                              </div>
                              <div className="col-6">
                                  {lookupProvinceModel && lookupProvinceModel.length > 0 && (
                                      <></>
                                      )}
                                  <FormAutocomplete
                                      label={"Tỉnh/thành phố"}
                                      optionsdata={lookupProvinceModel}
                                      setAutocompleteValue={setLookupProvince}
                                      name={"lookupDistrictId"}
                                      required={true}
                                      multiple={true}
                                  />
                                      {
                                      //    <label className="text-dark">
                                      //        Tỉnh/thành phố
                                      //</label>
                                      //<Autocomplete
                                      //    multiple
                                      //    options={lookupProvinceModel}
                                      //    getOptionLabel={(option) =>
                                      //        typeof option === "string" ? option : option.name
                                      //    }
                                      //    onChange={(event, newValue) => {
                                      //        setLookupProvince(newValue);
                                      //        if (newValue.length > 0) {
                                      //            setValue("lookupDistrictId", "11");
                                      //            //clearErrors("lookupDistrictId");
                                      //        } else {
                                      //            setValue("lookupDistrictId", "");
                                      //            //setError("lookupDistrictId", { type: "required" });
                                      //        }
                                      //    }}
                                      //    renderInput={(params) => (
                                      //        <TextField
                                      //            {...params}
                                                  
                                      //            variant="outlined"
                                      //            size="small"
                                      //        />
                                      //    )}
                                      ///>
                                      
                                      //{
                                      //    //error = {
                                      //    //    errors.lookupDistrictId &&
                                      //    //        errors.lookupDistrictId.type === "required"
                                      //    //}
                                      //    //<TextField
                                      //    //    name="lookupDistrictId"
                                      //    //    hidden
                                      //    //    inputRef={register({ required: true })}
                                      //    ///>
                                      //    //errors.lookupDistrictId &&
                                      //    //errors.lookupDistrictId.type === "required" && (
                                      //    //    <span className="error">Trường này là bắt buộc</span>
                                      //    //)
                                      //}
                                      }
                                  </div>

                              
                          </div>

                          <div className="form-group row">
                              {approvalAgencyModel && approvalAgencyModel.length > 0 && (
                                  <div className="col-6">
                                      <label className="text-dark">
                                          Cấp phê duyệt<span className="required"></span>
                                      </label>
                                      <Autocomplete
                                          options={approvalAgencyModel}
                                          getOptionLabel={(option) =>
                                              typeof option === "string" ? option : option.name
                                          }
                                          value={approvalAgency}
                                          onChange={(event, newValue) => {
                                              setApprovalAgency(newValue);
                                          }}
                                          disableClearable={true}
                                          renderInput={(params) => (
                                              <TextField
                                                  {...params}
                                                  name="approvalAgencyId"
                                                  inputRef={register({ required: true })}
                                                  error={
                                                      errors.approvalAgencyId &&
                                                      errors.approvalAgencyId.type === "required"
                                                  }
                                                  variant="outlined"
                                                  size="small"
                                              />
                                          )}
                                      />
                                      {errors.approvalAgencyId &&
                                          errors.approvalAgencyId.type === "required" && (
                                              <span className="error">Trường này là bắt buộc</span>
                                          )}
                                  </div>
                              )}
                              {planningStatusModel && planningStatusModel.length > 0 && (
                                  <div className="col-6">
                                      <label className="text-dark">
                                          Trạng thái<span className="required"></span>
                                      </label>

                                      <Autocomplete
                                          options={planningStatusModel}
                                          getOptionLabel={(option) =>
                                              typeof option === "string" ? option : option.name
                                          }
                                          value={planningStatus}
                                          onChange={(event, newValue) => {
                                              setPlanningStatus(newValue);
                                          }}
                                          disableClearable={true}
                                          renderInput={(params) => (
                                              <TextField
                                                  {...params}
                                                  name="planningStatusId"
                                                  inputRef={register({ required: true })}
                                                  error={
                                                      errors.planningStatusId &&
                                                      errors.planningStatusId.type === "required"
                                                  }
                                                  variant="outlined"
                                                  size="small"
                                              />
                                          )}
                                      />
                                      {errors.planningStatusId &&
                                          errors.planningStatusId.type === "required" && (
                                              <span className="error">Trường này là bắt buộc</span>
                                          )}
                                  </div>
                              )}

                              
                          </div>
                          <div className="form-group row">
                              {lookupProjectStatusModel && lookupProjectStatusModel.length > 0 && (
                                  <div className="col-6">
                                      <label className="text-dark">
                                          Tình trạng<span className="required"></span>
                                      </label>

                                      <Autocomplete
                                          options={lookupProjectStatusModel}
                                          getOptionLabel={(option) =>
                                              typeof option === "string" ? option : option.name
                                          }
                                          value={projectStatus}
                                          onChange={(event, newValue) => {
                                              setProjectStatus(newValue);
                                          }}
                                          disableClearable={true}
                                          renderInput={(params) => (
                                              <TextField
                                                  {...params}
                                                  name="projectStatusId"
                                                  inputRef={register({ required: true })}
                                                  error={
                                                      errors.projectStatusId &&
                                                      errors.projectStatusId.type === "required"
                                                  }
                                                  variant="outlined"
                                                  size="small"
                                              />
                                          )}
                                      />
                                      {errors.projectStatusId &&
                                          errors.projectStatusId.type === "required" && (
                                              <span className="error">Trường này là bắt buộc</span>
                                          )}
                                  </div>
                              )}
                              <div className="col-6">
                                  <label className="text-dark">
                                      Ngày hết hiệu lực<span className="required"></span>
                                  </label>
                                  <div className="w-100">
                                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={viLocale}>
                                          <KeyboardDatePicker
                                              id="expiredDate"
                                              name="expiredDate"
                                              variant="outlined"
                                              onChange={(date) => date && onSetExpiredDate(date)}
                                              format="dd/MM/yyyy"
                                              value={expiredDate}
                                              fullWidth

                                              showTodayButton={true}
                                          />
                                      </MuiPickersUtilsProvider>
                                  </div>
                              </div>
                          </div>
                          <div className="form-group row">
                              <div className="col-6">
                                  <label className="text-dark">
                                      File Tif
                      </label>
                                  <TextField
                                      fullWidth
                                      type="text"
                                      name="tifName"
                                      value={tifName}
                                      placeholder='Hãy tải file tif lên.'
                                      disabled
                                      variant="outlined"
                                      size="small"
                                  />
                                  <div className="mt-2">
                                      <Button
                                          variant="contained"
                                          color="primary"
                                          onClick={onOpenSelectTifFile}
                                      >
                                          Chọn file Tif
                    </Button>
                                  </div>
                              </div>
                              <div className="col-6">
                                  <label className="text-dark">
                                      File Zip
                      </label>
                                  <TextField
                                      fullWidth
                                      type="text"
                                      name="shpName"
                                      value={zipName}
                                      placeholder='Hãy tải file zip lên.'
                                      disabled
                                      variant="outlined"
                                      size="small"
                                  />
                                  <div className="mt-2">
                                      <Button
                                          variant="contained"
                                          color="primary"
                                          onClick={onOpenSelectZipFile}
                                      >
                                          Chọn file zip
                    </Button>
                                  </div>
                              </div>
                          </div>

                          <div className="form-group row">
                              <div className="col-12">
                                  <label className="text-dark">Mô tả</label>
                                  <textarea
                                      name="note"
                                      rows="5"
                                      className="form-control"
                                      ref={register}
                                  ></textarea>
                              </div>
                          </div>

                          <div className="form-group row">
                              <div className="col-6">
                                  <label className="text-dark">
                                      Ảnh<span className="required"></span>
                                  </label>
                                  {!isShow &&
                                      files &&
                                      files.length > 0 &&
                                      files.map((item) => (
                                          <div key={item.fileName} style={{ width: "150px" }}>
                                              <img
                                                  src={APIUrlDefault + item.filePreview}
                                                  alt={item.fileName}
                                                  className="img-fluid mb-2"
                                                  style={{
                                                      width: "auto",
                                                      height: "auto",
                                                      maxWidth: "100%",
                                                      maxHeight: "100%",
                                                  }}
                                              />
                                          </div>
                                      ))}
                                  <div>
                                      <Button
                                          variant="contained"
                                          color="primary"
                                          onClick={onOpenSelectFile}
                                      >
                                          Chọn file
                    </Button>
                                      <TextField
                                          inputRef={register({ required: true })}
                                          type="hidden"
                                          name="image"
                                          value={
                                              (files && files.length > 0 && files[0].fileName) || ""
                                          }
                                      />
                                      {errors.image && errors.image.type === "required" && (
                                          <p className="error">Trường này là bắt buộc</p>
                                      )}
                                  </div>
                              </div>
                              <div className="col-2">
                                  <label className="text-dark">Kích hoạt</label>
                                  <Checkbox
                                      checked={isActive }
                                      label="Kích hoạt"
                                      onChange={handleChangeStatus}
                                      color="primary"
                                      inputProps={{ "aria-label": "secondary checkbox" }}
                                      className="p-0 mt-0 ml-4"
                                  />
                              </div>
                              <div className="col-4 form-group ">
                                  <label className="text-dark">
                                      Từ khóa<span className="required"></span>
                                  </label>

                                  <Autocomplete
                                      multiple
                                      id="tags-filled"
                                      options={lookupTag}
                                      getOptionLabel={(option) =>
                                          typeof option === "string" ? option : option.name
                                      }
                                      freeSolo
                                      renderTags={(value, getTagProps) =>
                                          value.map((option, index) => (
                                              <Chip variant="filled" label={typeof option === "string" ? option : option.name} {...getTagProps({ index })} />
                                          ))
                                      }
                                      renderInput={(params) => (
                                          <TextField {...params} variant="outlined" size="small" />
                                      )}
                                  />
                              </div>
                          </div>
                      </DialogContent>

                      <DialogActions className="border-top">
                          <Button
                              type="button"
                              onClick={onClose}
                              variant="contained"
                              startIcon={<CloseIcon />}
                          >
                              Hủy
              </Button>
                          <Button
                              type="submit"
                              color="primary"
                              variant="contained"
                              startIcon={<SaveIcon />}
                          >
                              Lưu
              </Button>
                      </DialogActions>
                  </form>
        </Dialog>
      )}

      {isShow && (
        <Dialog
          onClose={onCloseSelectFile}
          open={isShow}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={files}
              setFiles={setFiles}
              acceptedFiles={["jpeg", "png", "jpg", "gif"]}
              filterExtension={'png'}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {files && files.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}

      {isShowTif && (
        <Dialog
          onClose={onCloseSelectTifFile}
          open={isShowTif}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectTifFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={tifFiles}
              setFiles={setTifFiles}
              acceptedFiles={["tif"]}
              isShowLeft={(false)}
              isShowAddFolder={(false)}
              isShowDownload={(true)}
              typeFile={'TIF'}
              cgisId={cgisId}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectTifFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {tifFiles && tifFiles.length > 0 && (tifFiles !== tifFilesTemp) && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectTifFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}

      {isShowZip && (
        <Dialog
          onClose={onCloseSelectZipFile}
          open={isShowZip}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectZipFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={zipFiles}
              setFiles={setZipFiles}
              acceptedFiles={["zip"]}
              isShowLeft={(false)}
              isShowAddFolder={(false)}
              isShowDownload={(true)}
              typeFile={'ZIP'}
              cgisId={cgisId}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectZipFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {zipFiles && zipFiles.length > 0 && (zipFiles !== zipFilesTemp) && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectZipFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}

      <Dialog onClose={onClose} open={openConfirmDialog}>
        <DialogTitle onClose={onClose}>
          <Typography variant="h6">{"Xác nhận"}</Typography>
        </DialogTitle>
        <DialogContent dividers>
          <h6>Bạn có muốn tạo dự án với trạng thái dự án cũ hay không?</h6>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            variant="contained"
            startIcon={<CloseIcon />}
            onClick={() => onAddData(true)}
          >
            Không
          </Button>
          <Button
            type="button"
            color="primary"
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={() => onAddData(false)}
          >
            Có
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddRecordsManagement);
