import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import * as config from "../../../common/config"

const service = new Service();

export const GetListAll = (categoryId=1,title="",isHot=undefined, isFeature=undefined,isDelete=undefined,status=undefined,
    pageIndex = 1, pageSize = config.Configs.DefaultPageSize, sortExpression = "modifiedDate desc") => {
    const params = new URLSearchParams();
    params.append('categoryId', categoryId);
    params.append('isHot', isHot);
    params.append('isFeature', isFeature);
    params.append('isDelete', isDelete);
    params.append('status', status);
    params.append('pageIndex',pageIndex);
    params.append('pageSize',pageSize);
    params.append('sorting',sortExpression);
    title && params.append('title',title);
    return service.get(ApiUrl.Notification_GetListAll,params).then(res => { return res }).catch(err => { throw err });
}

export const GetDetailNotification = (id) => {
    const params = new URLSearchParams();
    params.append("id", id);
    return service.get(ApiUrl.Notification_GetDetail.replace("{id}",id)).then(res => { return res }).catch(err => { throw err });
}

export const CreateNotification = (body) => {
    return service.post(ApiUrl.Notification_Create, body).then(res => { return res }).catch(err => { throw err });
}

export const UpdateNotification = (body) => {
    return service.post(ApiUrl.Notification_Update, body).then(res => { return res }).catch(err => { throw err });
}

export const DeleteNotification = (id, xmin) => {
    //const params = new URLSearchParams();
    //params.append("id",id);
    return service.postParams(ApiUrl.Notification_Delete + "/" + id + "/" + xmin).then(res => { return res }).catch(err => { throw err });
}

export const EmptyTrash = () => {
    return service.post(ApiUrl.Notification_EmptyTrash).then(res => { return res }).catch(err => { throw err });
}

export const RestoreNotification = (id) => {
    return service.postParams(ApiUrl.Notification_Restore.replace("{id}", id)).then(res => { return res }).catch(err => { throw err });
}