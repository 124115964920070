import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import * as config from "../../../common/config"

const service = new Service();

export const GetListMemberManagement = (pageIndex = 1, pageSize = config.Configs.DefaultPageSize, sortExpression = "", email = "") => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && params.append("sortExpression", sortExpression);
    email && params.append("email", email);
    return service.get(ApiUrl.GetListMemberManagement, params).then(res => { return res }).catch(err => { throw err });
}
export const GetDetailMemberManagement = (id) => {
    const params = new URLSearchParams();
    params.append("id", id);
    return service.get(ApiUrl.GetDetailMemberManagement.trim(), params).then(res => { return res }).catch(err => { throw err });
}

export const CreateMemberManagement = (body) => {
    return service.post(ApiUrl.CreateMemberManagement, body).then(res => { return res }).catch(err => { throw err });
}

export const UpdateMemberManagement = (body) => {
    return service.post(ApiUrl.UpdateMemberManagement, body).then(res => { return res }).catch(err => { throw err });
}

export const DeleteMemberManagement = (id) => {
    const params = new URLSearchParams();
    params.append("id", id);
    return service.postParams(ApiUrl.DeleteMemberManagement, params).then(res => { return res }).catch(err => { throw err });
}
export const GetRoleLookupMemberManagement = () => {
    return service.get(ApiUrl.GetRoleLookupMemberManagement).then(res => { return res }).catch(err => { throw err });
}
export const ActiveMember = (id) => {
    const params = new URLSearchParams();
    params.append("id", id);
    return service.postParams(ApiUrl.ActiveMember,params).then(res => { return res }).catch(err => { throw err });
}
export const DeActiveMember = (id) => {
    const params = new URLSearchParams();
    params.append("id", id);
    return service.postParams(ApiUrl.DeActiveMember,params).then(res => { return res }).catch(err => { throw err });
}
export const ResetPasswordMemberManagement = (id,password) => {
    const params = new URLSearchParams();
    params.append("UserId", id);
    params.append("Password", password);
    return service.postParams(ApiUrl.ResetPasswordMemberManagement,params).then(res => { return res }).catch(err => { throw err });
}
export const GetLookupPlanningKind = () => {
    return service.get(ApiUrl.GetLookupPlanningKind).then(res => { return res }).catch(err => { throw err });
}

export const GetLookupProvinceIds = () => {
    return service.get(ApiUrl.GetLookupProvinceIds).then(res => { return res }).catch(err => { throw err });
}