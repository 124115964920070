import React, { useState } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Configs } from "../../../common/config";
import * as configuration from "../../../utils/configuration";
import dateformat from "dateformat";

//--- Material Table
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";

//--- Material Icon
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import RestoreIcon from "@material-ui/icons/Restore";

//--- Material Control
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import * as videosAction from "../../../redux/store/videos/videos.store";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    tableContainer: {
        maxHeight: window.outerHeight - 365,
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

const headCells = [
    { id: "title", hideSortIcon: false, label: "Tiêu đề" },
    { id: "thumbnai", hideSortIcon: false, label: "Video Youtube" },
    { id: "status", hideSortIcon: false, label: "Kích hoạt" },
    { id: "isHot", hideSortIcon: false, label: "Tin nóng" },
    { id: "createdBy", hideSortIcon: false, label: "Người tạo" },
    { id: "createdDate", hideSortIcon: false, label: "Ngày tạo" },
    { id: "modifiedBy", hideSortIcon: false, label: "Người sửa" },
    { id: "modifiedDate", hideSortIcon: false, label: "Ngày sửa" },
    { id: "actions", hideSortIcon: true, label: "" },
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort,  } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={"pt-3 pb-3 text-nowrap" +
                            (headCell.id === "planningName" ? "MuiTableCell-freeze" : "")}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            hideSortIcon={headCell.hideSortIcon ? true : false}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function ListVideos(props) {
    const {
        videosModels,
        page,
        rowsPerPage,
        totalItemCount,
        Restore,
        ChangePageIndex,
        ChangePageSize,
        ChangeSort,
        handleOpenVideo,
        editAction,
        deleteAction,

    } = props;

    //--- Config table
    const classes = useStyles();
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("modifiedDate");

    //--- Handle sort, change page, change row per page
    const handleRequestSort = (event, property) => {
        if (property !== "avatar") {
            const isAsc = orderBy === property && order === "asc";
            setOrder(isAsc ? "desc" : "asc");
            setOrderBy(property);
            let sort = isAsc ? "desc" : "asc";
            let sortExpression = property + " " + sort;
            ChangeSort(sortExpression);

            //GetListAll(categoryId, title, undefined, undefined, isDelete, undefined, page + 1, rowsPerPage, sortExpression);
        }
    };

    const handleChangePage = (event, newPage) => {
        ChangePageIndex(newPage+1);
        //setPage(newPage);
        //let sortExpression = orderBy + " " + order;
        //GetListAll(categoryId, title, undefined, undefined, isDelete, undefined, newPage + 1, rowsPerPage, sortExpression);
    };

    const handleChangeRowsPerPage = (event) => {
        ChangePageSize(parseInt(event.target.value, 10));
        //setRowsPerPage(parseInt(event.target.value, 10));
        //setPage(0);
        //let sortExpression = orderBy + " " + order;
        //console.log(event.target.value);
        //GetListAll(categoryId, title, undefined, undefined, isDelete, undefined, 1, event.target.value, sortExpression);
    };

    const emptyRows =
        rowsPerPage -
        Math.min(rowsPerPage, videosModels.length - page * rowsPerPage);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer className={classes.tableContainer}>
                    <Table className={classes.table} size="small" stickyHeader>
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={videosModels.length}
                        />
                        <TableBody>
                            {videosModels && videosModels.length > 0 ? (
                                videosModels.map((row, index) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={row.id}>
                                            <TableCell className="columnWidth shadow-sm">{row.title}</TableCell>
                                            <TableCell onClick={()=>handleOpenVideo(row.linkYoutubeEmbed)}>
                                                <img
                                                    src={row.youtubeThumbnail}
                                                    onError={(e) =>
                                                        (e.target.src = process.env.PUBLIC_URL + "/logo.png")
                                                    }
                                                    alt={row.title}
                                                    className="logo"
                                                />

                                            </TableCell>
                                            <TableCell className="text-center">
                                                {row.status ? (
                                                    <img
                                                        src={require("../../../assets/icon/tick.png")}
                                                        alt="Tick"
                                                    />
                                                ) : (
                                                        <img
                                                            src={require("../../../assets/icon/cancel.png")}
                                                            alt="Cancel"
                                                        />
                                                    )}
                                            </TableCell>
                                            <TableCell className="text-center">
                                                {row.isHot ? (
                                                    <img
                                                        src={require("../../../assets/icon/tick.png")}
                                                        alt="Tick"
                                                    />
                                                ) : (
                                                        <img
                                                            src={require("../../../assets/icon/cancel.png")}
                                                            alt="Cancel"
                                                        />
                                                    )}
                                            </TableCell>
                                            <TableCell>{row.created_by}</TableCell>
                                            <TableCell>{dateformat(row.created_date, "dd/mm/yyyy")}</TableCell>
                                            <TableCell>{row.modified_by}</TableCell>
                                            <TableCell>{dateformat(row.modified_date, "dd/mm/yyyy")}</TableCell>
                                            <TableCell align="right" className="text-nowrap">
                                                <Tooltip title="Sửa">
                                                    <IconButton
                                                        aria-label="edit"
                                                        onClick={() => editAction(row.id)}
                                                    >
                                                        <EditIcon className="text-primary" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Xóa">
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={() => deleteAction(row.id, row.xmin)}
                                                    >
                                                        <DeleteIcon className="text-danger" />
                                                    </IconButton>
                                                </Tooltip>
                                                {
                                                    row.isDelete ? (
                                                        <Tooltip title="Khôi phục">
                                                            <IconButton
                                                                aria-label="restore"
                                                                onClick={() => Restore(row.id)}
                                                            >
                                                                <RestoreIcon className="text-success" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    ): ""
                                                }
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                    <TableRow hover tabIndex={-1}>
                                        <TableCell colSpan={8} className="text-center">
                                            Không có dữ liệu
                  </TableCell>
                                    </TableRow>
                                )}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 0 }}>
                                    <TableCell
                                        colSpan={8}
                                        style={{ padding: 0, borderBottom: 0 }}
                                    />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                {totalItemCount && totalItemCount > 0 ? (
                    <TablePagination
                        rowsPerPageOptions={Configs.DefaultPageSizeOption}
                        component="div"
                        count={totalItemCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage={"Số hàng mỗi trang"}
                        labelDisplayedRows={({ from, to, count }) => {
                            return "" + from + "-" + to + " trong " + count;
                        }}
                    />
                ) : (
                        ""
                    )}
            </Paper>
        </div>
    );
}
const mapStateToProps = state => ({
    videosModels: state.initVideos.videosModels,
    page: state.initVideos.pageIndex-1,
    rowsPerPage: state.initVideos.pageSize,
    totalItemCount: state.initVideos.totalItemCount,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetListAll: videosAction.GetList,
    Restore: videosAction.Restore,
    Delete: videosAction.ChangeShowTrash,
    GetDetail: videosAction.TrashEmpty,
    ChangePageIndex: videosAction.ChangePageIndex,
    ChangePageSize: videosAction.ChangePageSize,
    ChangeSort: videosAction.ChangeSort,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ListVideos)