import React, { useState, useEffect } from "react";
import { Configs } from "../../common/config";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";

//--- Material Control
import {
  Button,
  TextField,
  IconButton,
  Tooltip,
  Popover,
  Fab,
} from "@material-ui/core";

//--- Material Icon
import AddCircle from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import RefreshIcon from '@material-ui/icons/Refresh';

import DeleteDialog from "../../components/dialog-delete/dialog-delete.view";

//--- Notifications
import ShowNotification from "../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../utils/configuration";
import * as viVN from "../../language/vi-VN.json";

//--- Redux store
import * as projectAction from "../../redux/store/project-management/project.store";
import ListProjectManagement from "./list-project-management/list-project-management.view";
import EditProjectManagement from "./edit-project-management/edit-project-management.view";
import AddProjectManagement from "./add-project-management/add-project-management.view";
import * as appActions from "../../core/app.store";

function ProjectManagement(props) {
  const { showLoading } = props;
  const [projectModels, setProjectModels] = useState();
  const [totalItemCount, setTotalItemCount] = useState();
    const [keyword, setKeyword] = useState();
    const [governmentId, setGovernmentId] = useState();
    const [provinceId, setProvinceId] = useState();
    const [projectFieldId, setProjectFieldId] = useState();
    const [projectTypeId, setProjectTypeId] = useState();
    const [projectStatusId, setProjectStatusId] = useState();
    const [isActive, setIsActive] = useState();
  const [projectId, setProjectId] = useState();
  const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("modifiedDate");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(Configs.DefaultPageSize);
  const [status, setStatus] = useState();

  const { register, handleSubmit } = useForm({ mode: "all", reValidateMode: "onBlur" });

  useEffect(() => {
    GetListProject();
  }, [])

  const GetListProject = (pageIndex = 1, pageSize, sortExpression = orderBy + " " + order) => {
    showLoading(true);
    setPage(pageIndex - 1);
      projectAction.GetListAll(pageIndex, pageSize, sortExpression, keyword, governmentId, provinceId, projectFieldId, projectTypeId, projectStatusId, isActive)
          .then(res => {
      if (res && res.content) {
        setProjectModels(res.content.items);
        setTotalItemCount(res.content.totalItemCount);
              }
      else
          setProjectModels([]);
              showLoading(false);
    }).catch(err => {
      showLoading(false);
    });
  }

  //--- Dialog
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleOpenEditDialog = (projectId) => {
    setProjectId(projectId);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    GetListProject();
    setOpenEditDialog(false);
  };

  const handleOpenDeleteDialog = (projectId, status) => {
    setProjectId(projectId);
    setStatus(status);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleOpenRestPasswordDialog = (projectId) => {
    setProjectId(projectId);
  };

  //--- Filter
  const [filterSection, setFilterSection] = useState(null);

  const handleClickFilter = (event) => {
    setFilterSection(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterSection(null);
  };

  const ShowNotificationError = (messages) => {
    ShowNotification(messages, NotificationMessageType.Error)
  }

  const openFilter = Boolean(filterSection);
  const idFilter = openFilter ? 'popoverSlider' : undefined;

  const handleClearAllField = () => {
    setKeyword("");
    document.getElementById("formSearch").reset();
  }

  const onSubmit = async (data) => {
    await GetListProject(1, rowsPerPage, orderBy + " " + order);
    handleCloseFilter()
  }

  const refresh = () => {
      setKeyword("");
    setOrderBy("modifiedDate")
    setOrder("desc");
    GetListProject(1, rowsPerPage);
  }

  const handleDelete = () => {
    projectAction.Delete(projectId).then(
      (res) => {
        if (res && res.content && res.content.status) {
          GetListProject(1, rowsPerPage, orderBy ? (orderBy + " " + order) : "");
          handleCloseDeleteDialog();
          ShowNotification(
            viVN.Success.DeleteProject,
            NotificationMessageType.Success
          );
        }
      },
      (err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      }
    );
  };


  return (
    <div className="slider">
      <div className="d-sm-flex align-items-center justify-content-between mb-3">
        <h1 className="h3 mb-0 text-gray-800">
          Quản lý dự án
          <Tooltip title="Tìm kiếm">
            <Fab
              color="primary"
              aria-label="filter"
              size="small"
              className="ml-2"
              aria-describedby={idFilter}
              onClick={handleClickFilter}
            >
              <FilterListIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Refresh">
            <Fab color="primary" aria-label="filter" size="small" onClick={refresh} className="ml-2">
              <RefreshIcon />
            </Fab>
          </Tooltip>
          <Popover
            id={idFilter}
            open={openFilter}
            anchorEl={filterSection}
            onClose={handleCloseFilter}
            style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div className="p-3" style={{ width: '30rem' }}>
              <div className="text-right border-bottom mb-3 pb-2">
                <IconButton
                  aria-label="close"
                  size="small"
                  onClick={handleCloseFilter}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
              <form id="formSearch" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label className="text-dark">Từ khóa</label>
                  <TextField className="w-100" name="keyword" value={keyword} onChange={(e) => { setKeyword(e.target.value) }} inputRef={register} />
                </div>
                <div className="border-top">
                  <div className="row">
                    <div className="col-12 text-right mt-3">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                      >
                        <SearchIcon fontSize="small" /> Tìm kiếm
                      </Button>
                      <Button variant="contained" className="ml-2" onClick={handleClearAllField}>
                        <ClearAllIcon fontSize="small" /> Bỏ lọc
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Popover>
        </h1>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenAddDialog}
          startIcon={<AddCircle />}
        >
          Thêm dự án
        </Button>
      </div>
      {projectModels ? <ListProjectManagement
        totalItemCount={totalItemCount}
        projectModels={projectModels}
        GetListProject={GetListProject}
        editAction={handleOpenEditDialog}
        deleteAction={handleOpenDeleteDialog}
        setOrder={setOrder} setOrderBy={setOrderBy}
        setPage={setPage} setRowsPerPage={setRowsPerPage}
        order={order} orderBy={orderBy}
        page={page} rowsPerPage={rowsPerPage}
        restAction={handleOpenRestPasswordDialog}
      /> : ""}
      {openAddDialog && (
        <AddProjectManagement
          isOpen={openAddDialog}
          onClose={handleCloseAddDialog}
          onSuccess={handleCloseAddDialog}
          ShowNotificationError={ShowNotificationError}
          GetListProject={GetListProject}
          rowsPerPage={rowsPerPage}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          showLoading={showLoading}
        />
      )}

      {openEditDialog && (
        <EditProjectManagement
          isOpen={openEditDialog}
          onClose={handleCloseEditDialog}
          onSuccess={handleCloseEditDialog}
          projectId={projectId}
          ShowNotificationError={ShowNotificationError}
          GetListProject={GetListProject}
          rowsPerPage={rowsPerPage}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          showLoading={showLoading}
        />
      )}
      {openDeleteDialog ? (
        <DeleteDialog
          isOpen={openDeleteDialog}
          rowsPerPageCommon={rowsPerPage}
          onClose={handleCloseDeleteDialog}
          onSuccess={handleDelete}
        />
      ) : (
          ""
        )}
      {/* {openDeleteDialog && (status ?
        <DeleteDialog
          isOpen={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          onSuccess={}
          header={"Tài khoản này sẽ đang bị khoá"}
          content={"Bạn có muốn mở khoá tài khoản này không?"}
          btnName={"Mở khoá tài khoản"}
        />
        :
        <DeleteDialog
          isOpen={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          onSuccess={}
          header={"Tài khoản này đang được hoạt động"}
          content={"Bạn có muốn khoá tài khoản này không?"}
          btnName={"Khoá tài khoản"}
        />)
      } */}
    </div>
  );
}
const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProjectManagement);
