import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import * as config from "../../../common/config"

const service = new Service();
export const GetListAll = () => {
    return service.get(ApiUrl.Project_Field_GetListAll).then((res) => { return res }).catch(err => { throw err });
}
export const GetDetai = (id) => {
    return service.get(ApiUrl.Project_Field_GetDetail.replace("{id}", id)).then(res => { return res }).catch(err => { throw err });
}

export const GetLookup = () => {
    return service.get(ApiUrl.Project_Field_GetLookup).then(res => { return res }).catch(err => { throw err });
}

export const Create = (body) => {
    return service.post(ApiUrl.Project_Field_Create, body).then(res => { return res }).catch(err => { throw err });
}

export const Update = (body) => {
    return service.post(ApiUrl.Project_Field_Update, body).then(res => { return res }).catch(err => { throw err });
}

export const Delete = (id) => {
    return service.postParams(ApiUrl.Project_Field_Delete.replace("{id}", id)).then(res => { return res }).catch(err => { throw err });
}