import React, {useEffect, useState } from "react";
import { useForm } from "react-hook-form";
//--- Material Control
import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
    makeStyles,
    Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";

//--- Action
import * as categoryAction from "../../../redux/store/planning-category-management/planning-category.store";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function AddCategoryManagement(props) {
  const classes = useStyles();
  const {
    isOpen,
    onClose,
    onSuccess,
    GetListCategory,
    rowsPerPage,
    showLoading,
  } = props;

  const { register, handleSubmit, errors } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

    const [lookupParentCategory, setLookupParentCategory] = useState([]);
    const [parentCategory, setParentCategory] = useState(null);
    const [isActive, setIsActive] = useState(true);
    useEffect(() => {
        onGetData();
    }, []);

    const onGetData = () => {
        showLoading(true);
        Promise.all([
            getLookupParentCategory(),
        ])
            .then((res) => {
                
                const [
                    parentCategoryLookup,
                ] = res;
                parentCategoryLookup &&
                    parentCategoryLookup.content &&
                    setLookupParentCategory(parentCategoryLookup.content);
                showLoading(false);
            })
            .catch((err) => {
                showLoading(false);
            });
    };

    const getLookupParentCategory = () => {
        return new Promise((resolve, reject) => {
            categoryAction.GetLookup().then(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                }
            );
        });
    };

  const onSubmit = (data) => {
    if (!data) {
      return;
    }
      showLoading(true);
      if (parentCategory)
          data.parentId = parentCategory.id;
      data.isActive = isActive;
    categoryAction
      .Create(data)
      .then((result) => {
        if (result && result.content && result.content.status === true) {
          GetListCategory();
          showLoading(false);
          onSuccess();
          ShowNotification(
            viVN.Success.AddCategory,
            NotificationMessageType.Success
          );
        }
      })
      .catch((err) => {
        showLoading(false);
        ShowNotification(
          viVN.Errors[err.errorType],
          NotificationMessageType.Error
        );
      });
  };

    const handleChangeIsActive = (event) => {
        event.persist();
        setIsActive(event.target.checked);
    }

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Thêm tỉnh</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <DialogContent className="pt-4 pb-2">
            <div className="form-group">
              <div className="row">
                <div className="col-12">
                  <label className="text-dark">
                    Tên loại QH<span className="required"></span>
                  </label>
                  <TextField
                    type="text"
                    name="name"
                    className="w-100"
                    autoComplete="off"
                    inputRef={register({ required: true })}
                    error={errors.name && errors.name.type === "required"}
                  />
                  {errors.name && errors.name.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>
              </div>
            </div>
                      <div className="form-group">
                          <div className="row">
                              <div className="col-12">
                                  <label className="text-dark">
                                      Loại QH cấp trên<span className="required"></span>
                                  </label>
                                  {lookupParentCategory && lookupParentCategory.length > 0 && (
                                      <Autocomplete
                                          options={lookupParentCategory}
                                          getOptionLabel={(option) =>
                                              typeof option === "string" ? option : option.name
                                          }
                                          value={parentCategory}
                                          onChange={(event, newValue) => {
                                              setParentCategory(newValue);
                                          }}
                                          renderInput={(params) => (
                                              <TextField
                                                  {...params}
                                                  name="parentCategoryId"
                                                  inputRef={register()}
                                                  variant="outlined"
                                                  size="small"
                                              />
                                          )}
                                      />
                                  )
                                  }
                              </div>
                          </div>
                      </div>
                      <div className="form-group">
                          <div className="row">
                              <div className="col-12 col-md-4 col-lg-4">
                                  <label className="text-dark">Kích hoạt</label>
                                  <Checkbox
                                      checked={isActive}
                                      onChange={handleChangeIsActive}
                                      color="primary"
                                      inputProps={{ "aria-label": "secondary checkbox" }}
                                      className="p-0 mt-0 ml-4"
                                  />
                              </div>
                          </div>
                      </div>
          </DialogContent>

          <DialogActions className="border-top">
            <Button
              type="submit"
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
