import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const Login = (data) => {
  let apiUrl = '/api/cms/CmsAccount/LDAPLogin'
    if(data.checkUrl === 'vplan') {
      apiUrl = 'api/Account/Login'
    }
  return service
    .post(apiUrl, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const Register = (data) => {
  return service
    .post(ApiUrl.Register, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const ForgotPassword = (data) => {
  return service
    .post(ApiUrl.ForgotPassword, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const ResetPassword = (data) => {
  return service
    .post(ApiUrl.ResetPassword, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
