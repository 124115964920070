import React, { useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import dateformat from "dateformat";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@material-ui/core";

import { ACTION_TYPES } from "./utils";
import { useEffect } from "react";
import Editor from '../../components/form-input/editor.view';
import { MaxSizeImageUpload, } from "../../utils/configuration";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function DialogComponent(props) {
    const { register, handleSubmit, errors, clearErrors, setError } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

    const isShowUi = props.isShowUi;
    const maxWidth = props.maxWidth || "lg";
    const category = props.category;
    const government = props.government;
    const defaultCategoryId = props.selectedItems &&
        props.selectedItems.length > 0 &&
        props.selectedItems[0].categoryId;
    const defaultGovernmentId = props.selectedItems &&
        props.selectedItems.length > 0 &&
        props.selectedItems[0].governmentId;
    const defaultContent = props.selectedItems &&
        props.selectedItems.length > 0 &&
        props.selectedItems[0].content;
    const defaultPublishDate = props.selectedItems &&
        props.selectedItems.length > 0 &&
        props.selectedItems[0].publishDate;

    const [publishDate, setPublishDate] = useState(new Date(defaultPublishDate));
    const [content, setContent] = useState();
    const [categoryValue, setCategory] = useState({});
    const [governmentValue, setGovernment] = useState({});
    useEffect(() => {
        if (props.selectedItems &&
            props.selectedItems.length > 0 &&
            props.selectedItems[0].publishDate) {
            //setPublishDate(new Date(props.selectedItems[0].publishDate));
            //setPublishDate(props.selectedItems[0].publishDate);
        }
        
    });
  const onSubmitCreateFolder = (data) => {
    if (!data) return;

    props.handleSuccess && props.handleSuccess(data.folderName || "");
  };

    const onChangeContent = (editorContent) => {
        setContent(editorContent);
    };

    const onSetPublishDate = (date) => {
        if (!isNaN(date))
            setPublishDate(dateformat(date, "yyyy-mm-dd"));
        else
            setPublishDate(null);
    }

  const onSubmitRenameDocument = (data) => {
    if (!data) return;

    let params = {
      documentId:
        (props.selectedItems &&
          props.selectedItems.length > 0 &&
          props.selectedItems[0].id) ||
        null,
        newFileName: data.name,
        title: data.title,
        description: data.description,
        docNumber: data.docNumber,
        content: content || defaultContent,
        categoryId: categoryValue && categoryValue.id ? categoryValue.id : defaultCategoryId,
        governmentId: governmentValue && governmentValue.id ? governmentValue.id : defaultGovernmentId,
        publishDate: (publishDate && moment(publishDate).format("YYYY-MM-DDTHH:mm:ssZ")) || null,
      };
    props.handleSuccess && props.handleSuccess(params);
  };

  const renderTitle = () => {
    switch (props.actionType) {
      case ACTION_TYPES.COPY:
        return "Sao chép tới";
      case ACTION_TYPES.MOVE:
        return "Di chuyển tới";
      case ACTION_TYPES.RENAME:
        return `Đổi tên '${
          props.selectedItems &&
          props.selectedItems.length > 0 &&
          props.selectedItems[0].name
        }'`;
      case ACTION_TYPES.DELETE:
        return `Bạn chắc chắn muốn xóa '${
          props.selectedItems &&
          props.selectedItems.length > 0 &&
          props.selectedItems.map((item) => {
            return item.name;
          })
        }'`;
      case ACTION_TYPES.CREATE_FOLDER:
        return "Thư mục mới";
      case ACTION_TYPES.UPLOAD_FILE:
        return "Tải lên tệp";
      case ACTION_TYPES.PREVIEW_FILE:
        return "Xem trước file";
      default:
        return "";
    }
  };

  const renderContentAction = () => {
    const actionType = props.actionType || "";
    switch (actionType) {
      case ACTION_TYPES.COPY:
        return (
          <div>
            <DialogContent dividers>
              {props.renderContent && props.renderContent()}
            </DialogContent>
            <DialogActions>
              <Button
                type="button"
                onClick={props.handleSuccess}
                color="primary"
              >
                Sao chép
              </Button>
              <Button type="button" onClick={props.handleClose} color="primary">
                Hủy
              </Button>
            </DialogActions>
          </div>
        );
      case ACTION_TYPES.MOVE:
        return (
          <div>
            <DialogContent dividers>
              {props.renderContent && props.renderContent()}
            </DialogContent>
            <DialogActions>
              {props.selectedFolder && props.selectedFolder instanceof Object && (
                <Button
                  type="button"
                  onClick={props.handleSuccess}
                  color="primary"
                >
                  Di chuyển
                </Button>
              )}
              <Button type="button" onClick={props.handleClose} color="primary">
                Hủy
              </Button>
            </DialogActions>
          </div>
        );
        case ACTION_TYPES.RENAME:
        return (
          <form onSubmit={handleSubmit(onSubmitRenameDocument)}>
                <DialogContent dividers>
                    {
                        props.selectedItems &&
                        props.selectedItems.length > 0 &&
                        props.selectedItems[0].type == 1 && isShowUi &&
                        (
                            <div>
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="form-group">
                                            <TextField
                                                type="text"
                                                name="title"
                                                defaultValue={
                                                    props.selectedItems &&
                                                    props.selectedItems.length > 0 &&
                                                    props.selectedItems[0].title
                                                }
                                                placeholder="Nhập tiêu đề..."
                                                className="w-100"
                                                inputRef={register}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <div className="form-group">
                                                <TextField
                                                    type="text"
                                                    name="docNumber"
                                                    defaultValue={
                                                        props.selectedItems &&
                                                        props.selectedItems.length > 0 &&
                                                        props.selectedItems[0].docNumber
                                                    }
                                                    placeholder="Nhập số hiệu văn bản..."
                                                    className="w-100"
                                                    inputRef={register}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <div className="form-group">
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        id="publishDateId"
                                                        name="publishDateId"
                                                        onChange={(date) => onSetPublishDate(date)}
                                                        format="dd/MM/yyyy"
                                                        value={publishDate}
                                                        fullWidth
                                                        showTodayButton={true}
                                                        error={
                                                            errors.publishDateId &&
                                                            errors.publishDateId.type === "required"
                                                        }
                                                    />
                                                </MuiPickersUtilsProvider>
                                                {errors.publishDateId &&
                                                    errors.publishDateId.type === "required" && (
                                                        <span className="error">
                                                            Phải nhập ngày tháng
                                                        </span>
                                                    )}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="form-group">
                                            <div className="form-group">
                                                <textarea
                                                    placeholder="Trích yếu nội dung"
                                                    defaultValue={
                                                        props.selectedItems &&
                                                        props.selectedItems.length > 0 &&
                                                        props.selectedItems[0].description
                                                    }
                                                    name="description"
                                                    rows="5"
                                                    ref={register({ required: true, maxLength: 500 })}
                                                    maxLength="500"
                                                    className={
                                                        "form-control" +
                                                        (errors.description && errors.description.type === "required"
                                                            ? " is-invalid"
                                                            : "")
                                                    }
                                                ></textarea>
                                                {errors.description && errors.description.type === "required" && (
                                                    <span className="error">Trường này là bắt buộc</span>
                                                )}
                                                {errors.description && errors.description.type === "maxLength" && (
                                                    <span className="error">Trường này không quá 500 ký tự</span>
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                    {
                                        //<div className="col-6">
                                        //    <div className="row">
                                        //        <div className="col-12">
                                        //            <label className="text-dark">
                                        //                Loại quy hoạch<span className="required"></span>
                                        //            </label>

                                        //            {category && category.length > 0 && (
                                        //                <Autocomplete
                                        //                    options={category}
                                        //                    getOptionLabel={(option) =>
                                        //                        typeof option === "string" ? option : option.name
                                        //                    }
                                        //                    value={
                                        //                        (categoryValue.id && categoryValue) || (category.some(
                                        //                            (item) => item.id === defaultCategoryId
                                        //                        )
                                        //                            ? category.find(
                                        //                                (item) =>
                                        //                                    item.id === defaultCategoryId
                                        //                            )
                                        //                            : null)
                                        //                    }
                                        //                    onChange={(event, newValue) => {
                                        //                        setCategory(newValue);
                                        //                    }}
                                        //                    disableClearable={true}
                                        //                    renderInput={(params) => (
                                        //                        <TextField
                                        //                            {...params}
                                        //                            name="planningCategoryId"
                                        //                            inputRef={register({ required: true })}
                                        //                            error={
                                        //                                errors.planningCategoryId &&
                                        //                                errors.planningCategoryId.type === "required"
                                        //                            }
                                        //                            variant="outlined"
                                        //                            size="small"
                                        //                        />
                                        //                    )}
                                        //                />
                                        //            )}
                                        //            {errors.planningCategoryId &&
                                        //                errors.planningCategoryId.type === "required" && (
                                        //                    <span className="error">Trường này là bắt buộc</span>
                                        //                )}
                                        //        </div>
                                        //    </div>
                                        //    <div className="row">
                                        //        <div className="col-12">
                                        //            <label className="text-dark">
                                        //                CQ ban hành<span className="required"></span>
                                        //            </label>

                                        //            {government && government.length > 0 && (
                                        //                <Autocomplete
                                        //                    options={government}
                                        //                    getOptionLabel={(option) =>
                                        //                        typeof option === "string" ? option : option.name
                                        //                    }
                                        //                    value={
                                        //                        (governmentValue.id && governmentValue) || (government.some(
                                        //                            (item) => item.id === defaultGovernmentId
                                        //                        )
                                        //                            ? government.find(
                                        //                                (item) =>
                                        //                                    item.id === defaultGovernmentId
                                        //                            )
                                        //                            : null)
                                        //                    }
                                        //                    onChange={(event, newValue) => {
                                        //                        setGovernment(newValue);
                                        //                    }}
                                        //                    disableClearable={true}
                                        //                    renderInput={(params) => (
                                        //                        <TextField
                                        //                            {...params}
                                        //                            name="fileGovernmentId"
                                        //                            inputRef={register({ required: true })}
                                        //                            error={
                                        //                                errors.fileGovernmentId &&
                                        //                                errors.fileGovernmentId.type === "required"
                                        //                            }
                                        //                            variant="outlined"
                                        //                            size="small"
                                        //                        />
                                        //                    )}
                                        //                />
                                        //            )}
                                        //            {errors.fileGovernmentId &&
                                        //                errors.fileGovernmentId.type === "required" && (
                                        //                    <span className="error">Trường này là bắt buộc</span>
                                        //                )}
                                        //        </div>
                                        //    </div>
                                        //</div>
                                    }
                                </div>
                                {
                                    //<div className="row">
                                    //    <div className="col-12 col-md-12 col-lg-12">
                                    //        <Editor
                                    //            imageAccept=".jpg,.jpeg,.png,.gift,.svg"
                                    //            imageUploadSizeLimit={MaxSizeImageUpload}
                                    //            imageUploadUrl="https://localhost:44348/api/admin/File/UploadImage"
                                    //            onChangeContent={onChangeContent}
                                    //            defaultContent={defaultContent}
                                    //            height={500}
                                    //            required={true}
                                    //        />
                                    //    </div>
                                    //</div>
                                }
                            </div>
                        )
                    }
                    <div className="row">
                    <div className="col-12 col-md-12 col-lg-12">
                            <div className="form-group">
                                <InputLabel>
                                    {
                                        props.selectedItems &&
                                        props.selectedItems.length > 0 &&
                                        props.selectedItems[0].type==1 ? "Tên file" : "Tên thư mục"
                                    }
                                    
                                    <span className="required"></span>
                                </InputLabel>
                            <TextField
                                type="text"
                                name="name"
                                defaultValue={
                                    props.selectedItems &&
                                    props.selectedItems.length > 0 &&
                                    props.selectedItems[0].name
                                }
                                placeholder="Nhập tên file..."
                                className="w-100"
                                inputRef={register({ required: true })}
                                error={errors.name && errors.name.type === "required"}
                            />
                            {errors.name && errors.name.type === "required" && (
                                <span className="error">Trường này là bắt buộc</span>
                            )}
                        </div>
                
                        </div>
                    </div>
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary">
                Đổi tên
              </Button>
              <Button type="button" onClick={props.handleClose} color="primary">
                Hủy
              </Button>
            </DialogActions>
          </form>
        );
      case ACTION_TYPES.DELETE:
        return (
          <div>
            <DialogContent dividers>
              <div>Bạn không thể khôi phục sau khi xóa. Xác nhận xóa.</div>
            </DialogContent>
            <DialogActions>
              <Button
                type="button"
                onClick={props.handleSuccess}
                color="primary"
              >
                Xóa
              </Button>
              <Button type="button" onClick={props.handleClose} color="primary">
                Hủy
              </Button>
            </DialogActions>
          </div>
        );
      case ACTION_TYPES.CREATE_FOLDER:
        return (
          <form onSubmit={handleSubmit(onSubmitCreateFolder)}>
            <DialogContent dividers>
              <div>
                <TextField
                  type="text"
                  name="folderName"
                  placeholder="Nhập tên thư mục..."
                  className="w-100"
                  inputRef={register({ required: true, maxLength: 255 })}
                  error={
                    errors.folderName &&
                    (errors.folderName.type === "required" ||
                      errors.folderName.type === "maxLength")
                  }
                />
                {errors.folderName && errors.folderName.type === "required" && (
                  <span className="error">Trường này là bắt buộc</span>
                )}
                {errors.folderName &&
                  errors.folderName.type === "maxLength" && (
                    <span className="error">Tối đa 255 ký tự</span>
                  )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary">
                Xác nhận
              </Button>
              <Button type="button" onClick={props.handleClose} color="primary">
                Hủy
              </Button>
            </DialogActions>
          </form>
        );
      case ACTION_TYPES.UPLOAD_FILE:
        return (
          <div>
            <DialogContent dividers>
              {props.renderContent && props.renderContent()}
            </DialogContent>
            <DialogActions>
              {props.fileUpload && props.fileUpload.length > 0 && (
                <Button
                  type="button"
                  onClick={props.handleSuccess}
                  color="primary"
                >
                  Tải lên
                </Button>
              )}
              <Button type="button" onClick={props.handleClose} color="primary">
                Hủy
              </Button>
            </DialogActions>
          </div>
        );
      case ACTION_TYPES.PREVIEW_FILE:
        return (
          <div>
            <DialogContent dividers>
              {props.renderContent && props.renderContent()}
            </DialogContent>
            <DialogActions>
              <Button type="button" onClick={props.handleClose} color="primary">
                Hủy
              </Button>
            </DialogActions>
          </div>
        );
      default:
        return (
          <div>
            <DialogContent dividers></DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose} color="primary">
                Hủy
              </Button>
            </DialogActions>
          </div>
        );
    }
  };
  return (
      <Dialog
      onClose={props.handleClose}
      open={props.open}
      fullWidth={true}
          maxWidth={maxWidth}
    >
      <DialogTitle onClose={props.handleClose}>{renderTitle()}</DialogTitle>
      {renderContentAction()}
    </Dialog>
  );
}
