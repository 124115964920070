import React, { useEffect, useState } from "react";
import "./planning-provincial.scss";
import * as planningAction from "./../../redux/store/planning/planning.store";
import ShowNotification from "./../../components/react-notifications/react-notifications";
import * as viVN from "./../../language/vi-VN.json";
import { NotificationMessageType } from "./../../utils/configuration";
import moment from "moment";
import {
  Button,
  Fab,
  FormControl,
  IconButton,
  MenuItem,
  Popover,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
//--- Material Icon
import AddCircle from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import RefreshIcon from "@material-ui/icons/Refresh";
import { useForm } from "react-hook-form";
import { Configs } from "../../common/config";
import ListCategoryManagement from "./list-category-management/list-category-management.view";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../core/app.store";
import * as proviceAction from "./../../redux/store/province-management/province.store";
import { Autocomplete } from "@material-ui/lab";
const statues = [
  {
    value: 0,
    name: "---không chọn---",
  },
  {
    value: 1,
    name: "Chưa nộp",
  },
  {
    value: 2,
    name: "Đã nộp",
  },
  {
    value: 3,
    name: "Đã tiếp nhận",
  },
  {
    value: 4,
    name: "Đã phê duyệt",
  },
  {
    value: 5,
    name: "Đã cập nhật lên hệ thống",
  },
];
function PlanningProvincial(props) {
  const { showLoading } = props;
  const [categoryModels, setCategoryModels] = useState();
  const [totalItemCount, setTotalItemCount] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(Configs.DefaultPageSize);

  const [year, setYear] = useState();
  const [yearData, setYearData] = useState([]);
  const [recordsStatus, setRecordsStatus] = useState(statues[0]);
  const [records, setRecords] = useState([]);
  const [page, setPage] = useState(1);
  useEffect(() => {
    GetListCategory();
  }, []);

  //const GetListCategory = (pageIndex = 1, pageSize, sortExpression = orderBy + " " + order) => {
  const GetListCategory = (pageIndex = 1, pageSize) => {
    let param = `?PlanningKindId=1`;
    if (year) {
      param = param + `&Year=${year}`;
    }
    if (recordsStatus.value) {
      param = param + `&RecordsStatus=${recordsStatus.value}`;
    }
    if (provice) {
      param = param + `&ProvinceId=${provice.id}`;
    }
    if (pageIndex) {
      param = param + `&pageIndex=${pageIndex}`;
    }
    if (pageSize) {
      param = param + `&pageSize=${pageSize}`;
    }
    showLoading(true);
    setPage(pageIndex - 1);
    planningAction
      .GetRecordsAll(param)
      .then((res) => {
        if (res && res.content) {
          setCategoryModels(res.content.items);
          setTotalItemCount(res.content.totalItemCount);
          //setTotalItemCount(res.content.totalItemCount);
        } else setCategoryModels([]);
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };
  const [proviceSelect, setProviceSelect] = useState();
  const [provice, setProvice] = useState();
  useEffect(() => {
    proviceAction.GetLookupProvince().then((res) => {
      setProviceSelect(res && res.content ? res.content : []);
    });
  }, []);
  useEffect(() => {
    const yearFake = new Date().getFullYear();
    const tmp = [];
    for (let index = yearFake - 5; index < yearFake + 10; index++) {
      tmp.push(index);
    }
    setYearData(tmp);
  }, []);
  useEffect(() => {
    let param = `?PlanningKindId=1`;
    if (year) {
      param = param + `&Year=${year}`;
    }
    if (recordsStatus.value) {
      param = param + `&RecordsStatus=${recordsStatus.value}`;
    }
    if (provice) {
      param = param + `&ProvinceId=${provice.id}`;
    }
   
    param = param + `&pageIndex=${1}`;
    param = param + `&pageSize=${rowsPerPage}`;
   
    planningAction
      .GetRecordsAll(param)
      .then((res) => {
        if (res && res.content && res.content) {
          setRecords(res.content.items);
        }
      })
      .catch((err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      });
  }, []);
  const refresh = () => {
    setProvice();
    setRecordsStatus(statues[0]);
    setYear();
    let param = `?PlanningKindId=1`;
    if (year) {
      param = param + `&Year=${year}`;
    }
    if (recordsStatus.value) {
      param = param + `&RecordsStatus=${recordsStatus.value}`;
    }
    if (provice) {
      param = param + `&ProvinceId=${provice.id}`;
    }
    planningAction
      .GetRecordsAll(param)
      .then((res) => {
        if (res && res.content) {
          setCategoryModels(res.content.items);
          setTotalItemCount(res.content.totalItemCount);
          //setTotalItemCount(res.content.totalItemCount);
        } else setCategoryModels([]);
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };
  const [filterSection, setFilterSection] = useState(null);

  const handleClickFilter = (event) => {
    setFilterSection(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterSection(null);
  };

  const openFilter = Boolean(filterSection);
  const idFilter = openFilter ? "popoverSlider" : undefined;
  const { register, handleSubmit } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });
  const onSubmit = async (data) => {
   let param = `?PlanningKindId=1`;
    if (year) {
      param = param + `&Year=${year}`;
    }
    if (recordsStatus.value) {
      param = param + `&RecordsStatus=${recordsStatus.value}`;
    }
    if (provice) {
      param = param + `&ProvinceId=${provice.id}`;
    }
    param = param + `&pageIndex=${1}`;
    param = param + `&pageSize=${rowsPerPage}`;
    planningAction
      .GetRecordsAll(param)
      .then((res) => {
        if (res && res.content) {
          setCategoryModels(res.content.items);
          setTotalItemCount(res.content.totalItemCount);
          //setTotalItemCount(res.content.totalItemCount);
        } else setCategoryModels([]);
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
    handleCloseFilter();
  };
  const handleClearAllField = () => {
    document.getElementById("formSearch").reset();
  };

  const updateItem  = (id, value) => {
    if(value === 0) {
      return;
    }
    const data = categoryModels.find(item => item.id === id);
    data.recordsStatus = value
    const param = `/${id}/${value}`
    planningAction
        .UpdateRecords(param)
        .then((res) => {
          if (res && res.content && res.content) {
            const tmp = categoryModels.filter(item => item.id !== id); 
            tmp.unshift(data)
            setCategoryModels(tmp)
            ShowNotification(
              viVN.Success.ChangeProjectSuccess,
              NotificationMessageType.Success
            );
          }
        })
        .catch((err) => {
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        });
  }
  return (
    <div className="slider">
      <div className="d-sm-flex align-items-center justify-content-between mb-3">
        <h1 className="h3 mb-0 text-gray-800">
          Quy hoạch Tỉnh
          <Tooltip title="Tìm kiếm">
            <Fab
              color="primary"
              aria-label="filter"
              size="small"
              className="ml-2"
              aria-describedby={idFilter}
              onClick={handleClickFilter}
            >
              <FilterListIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Refresh">
            <Fab
              color="primary"
              aria-label="filter"
              size="small"
              onClick={refresh}
              className="ml-2"
            >
              <RefreshIcon />
            </Fab>
          </Tooltip>
          <Popover
            id={idFilter}
            open={openFilter}
            anchorEl={filterSection}
            onClose={handleCloseFilter}
            style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <div className="p-3" style={{ width: "30rem" }}>
              <div className="text-right border-bottom mb-3 pb-2">
                <IconButton
                  aria-label="close"
                  size="small"
                  onClick={handleCloseFilter}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
              <form id="formSearch" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label className="text-dark">Trạng thái</label>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    className="input-select"
                    name="startYear"
                    style={{ width: "100%" }}
                    value={recordsStatus}
                    onChange={(e) => setRecordsStatus(e.target.value)}
                  >
                    {statues.map((item) => (
                      <MenuItem key={item.value} value={item}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <div className="form-group">
                  <label className="text-dark">Năm</label>
                  <Autocomplete
                    options={yearData}
                    value={year}
                    onChange={(event, newValue) => {
                      setYear(newValue);
                    }}
                    getOptionLabel={(option) => option.toString()}
                    id="controllable-states-demo"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Năm" />
                    )}
                  />
                </div>
                <div className="form-group">
                  <label className="text-dark">Tỉnh</label>
                  <Autocomplete
                    options={proviceSelect}
                    value={provice}
                    onChange={(event, newValue) => {
                      setProvice(newValue);
                    }}
                    getOptionLabel={(option) => option.name}
                    id="controllable-states-demo"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Tỉnh" />
                    )}
                  />
                </div>
                <div className="border-top">
                  <div className="row">
                    <div className="col-12 text-right mt-3">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                      >
                        <SearchIcon fontSize="small" /> Tìm kiếm
                      </Button>
                      <Button
                        variant="contained"
                        className="ml-2"
                        onClick={handleClearAllField}
                      >
                        <ClearAllIcon fontSize="small" /> Bỏ lọc
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Popover>
        </h1>
      </div>
      <ListCategoryManagement
        totalItemCount={totalItemCount}
        categoryModels={categoryModels}
        GetListCategory={GetListCategory}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        // restAction={handleOpenRestPasswordDialog}
        updateItem={updateItem}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PlanningProvincial);
