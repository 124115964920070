import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import { ApiUrl } from "../../../api/api-url";

//--- Styles
import "../videos.scss";

//--- Material Control
import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
  Checkbox,
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import {
  NotificationMessageType,
  APIUrlDefault,
  MaxSizeImageUpload,
} from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

//--- Action
import * as videosAction from "../../../redux/store/videos/videos.store";
import FileManagement from "../../../components/file_management/file_management";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

function AddVideos(props) {
  const classes = useStyles();

  const { isOpen, onClose, onSuccess, Create } = props;

  const [status, setStatus] = useState(true);
  const [isHot, setIsHot] = useState(true);
  const [image_Url, setImage_Url] = useState("");
  const [content, setContent] = useState();
  const [isShow, setShow] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesTemp, setFilesTemp] = useState([]);
  const urlUploadImage = APIUrlDefault + ApiUrl.UrlUploadFromEditor;

  const { register, handleSubmit, setError, errors, clearErrors, setValue } =
    useForm({
      mode: "all",
      reValidateMode: "onBlur",
    });

  const onChangeContent = (editorContent) => {
    clearErrors(["editorContent"]);
    if (editorContent === "<p><br></p>") {
      setError("editorContent", { type: "required" });
      setContent("");
    } else {
      clearErrors("editorContent");
      setContent(editorContent);
    }
  };

  const onSubmit = (data) => {
    if (!data) {
      return;
    }
    let formData = new FormData();
    data.title && formData.append("title", data.title);
    formData.append("status", status ? 1 : 0);
    formData.append("isHot", isHot ? 1 : 0);
    formData.append("isFeature", 0);
    formData.append("isHomePage", 0);
    data.linkYoutube && formData.append("linkYoutube", data.linkYoutube);
    if (Create(formData)) onSuccess();
  };

  const handleChangeStatus = (event) => {
    event.persist();
    setStatus(event.target.checked);
  };

  const handleChangeHot = (event) => {
    event.persist();
    setIsHot(event.target.checked);
  };

  const onOpenSelectFile = () => {
    setShow(true);
    setFilesTemp(files);
  };

  const onCloseSelectFile = () => {
    setShow(false);
    setFiles(filesTemp);
  };

  const onSaveSelectFile = () => {
    console.log(files);
    setShow(false);
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="lg">
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Thêm video</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <DialogContent className="pt-4 pb-2">
            <div className="form-group">
              <label className="text-dark">
                Tiêu đề<span className="required"></span>
              </label>
              <TextField
                name="title"
                error={
                  errors.title &&
                  (errors.title.type === "required" ||
                    errors.title.type === "maxLength")
                }
                fullWidth
                type="text"
                className="form-control"
                inputRef={register({ required: true, maxLength: 150 })}
                inputProps={{ maxLength: 150 }}
                onChange={(e) => setValue("title", e.target.value)}
              />
              {errors.title && errors.title.type === "required" && (
                <span className="error">Trường này là bắt buộc</span>
              )}
              {errors.title && errors.title.type === "maxLength" && (
                <span className="error">Trường này không quá 150 ký tự</span>
              )}
            </div>

            <div className="form-group">
              <label className="text-dark">Thêm video từ Youtube <span className="required"></span></label>
              <TextField
                name={"linkYoutube"}
                placeholder={"https://www.youtube.com/watch?v=uEP92B9zB5c"}
                error={
                  errors.linkYoutube && errors.linkYoutube.type === "pattern"
                }
                //defaultValue={currentProject?.linkYoutube}
                fullWidth
                type="text"
                className="form-control"
                inputRef={register({
                  required: true,
                  pattern:
                  /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/,
                })}
              />
              {errors.linkYoutube && errors.linkYoutube.type === "pattern" && (
                <span className="error">
                  Trường này chưa phải là liên kết youtube
                </span>
              )}
               {errors.linkYoutube && errors.linkYoutube.type === "required" && (
                <span className="error">Trường này là bắt buộc</span>
              )}
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-12 col-md-4 col-lg-4">
                  <label className="text-dark">Kích hoạt</label>
                  <Checkbox
                    checked={status}
                    label="Kích hoạt"
                    onChange={handleChangeStatus}
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    className="p-0 mt-0 ml-4"
                  />
                </div>
                <div className="col-12 col-md-4 col-lg-4">
                  <label className="text-dark">Tin nóng</label>
                  <Checkbox
                    checked={isHot}
                    onChange={handleChangeHot}
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    className="p-0 mt-0 ml-4"
                  />
                </div>
              </div>
            </div>
          </DialogContent>

          <DialogActions className="border-top">
            <Button
              type="submit"
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {isShow && (
        <Dialog
          onClose={onCloseSelectFile}
          open={isShow}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={files}
              setFiles={setFiles}
              acceptedFiles={["jpeg", "png", "jpg", "gif"]}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {files && files.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      Create: videosAction.Create,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddVideos);
