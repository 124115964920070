import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

//--- Action
import * as projectAction from "../../../redux/store/project-management/project.store";

//--- Material Control
import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  makeStyles,
  Typography,
    IconButton,
  Checkbox
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

//--- Notifications
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";

//--- Action
import * as provinceAction from "../../../redux/store/province-management/province.store";
import * as governmentAction from "../../../redux/store/government-management/government.store";
import * as projectStatusAction from "../../../redux/store/project-status-management/project-status.store";
import * as projectTypeAction from "../../../redux/store/project-management/project-type.store";
import * as projectFieldAction from "../../../redux/store/project-management/project-field.store";
import FileManagement from "../../../components/file_management/file_management";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function EditProjectManagement(props) {
  const classes = useStyles();

  const {
    isOpen,
    onClose,
    onSuccess,
    projectId,
    setOrder,
    setOrderBy,
    showLoading,
  } = props;

    const [projectModel, setProjectModel] = useState();
    const [lookupProjectFieldModel, setLookupProjectFieldModel] = useState([]);
    const [projectField, setProjectField] = useState(null);
    const [lookupProjectTypeModel, setLookupProjectTypeModel] = useState([]);
    const [projectType, setProjectType] = useState(null);
    const [lookupProjectStatusModel, setLookupProjectStatusModel] = useState([]);
    const [projectStatus, setProjectStatus] = useState(null);
    const [lookupGovernmentModel, setLookupGovernmentModel] = useState([]);
    const [government, setGovernment] = useState(null);
    const [lookupProvinceModel, setLookupProvinceModel] = useState([]);
    const [province, setProvince] = useState(null);
    const [isActive, setIsActive] = useState(true);
    const [isShowAddFile, setShowAddFile] = useState(false);
    const [files, setFiles] = useState([]);
    const [filesTemp, setFilesTemp] = useState([]);

  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

    useEffect(() => {
        showLoading(true);
        onGetData();
  }, []);

    const onGetDetailProject = (id = projectId) => {
        return new Promise((resolve, reject) => {
            projectAction.GetDetai(id).then(
                (res) => {
                    if (!res || !res.content) {
                        reject(res);
                    }
                    setProjectModel(res.content);
                    setIsActive(res.content.isActive == 1 ? true : false);
                    setFiles(res.content.projectFiles && res.content.projectFiles.length > 0 ? [res.content.projectFiles[0].files] : []);
                    resolve(res);
                },
                (err) => {
                    reject(err);
                    ShowNotification(
                        viVN.Errors[(err && err.errorType) || "UnableHandleException"],
                        NotificationMessageType.Error
                    );
                }
            );
        });
    };

  const onSubmit = (data) => {
    if (!data) {
      return;
    }
      showLoading(true);

      data.projectFieldId = projectField ? projectField.id : projectModel.projectFieldId;
      data.projectTypeId = projectType ? projectType.id : projectModel.projectTypeId;
      data.projectStatusId = projectStatus ? projectStatus.id : projectModel.projectStatusId;
      data.governmentId = government ? government.id : projectModel.governmentId;
      data.provinceId = province ? province.id : projectModel.provinceId;

      data.id = projectId;
      data.planningId = projectModel.planningId;
      data.layerId = projectModel.layerId;
      data.gId = projectModel.gId;
      data.layerCategoryId = projectModel.layerCategoryId;
      data.isActive = isActive;

      if (files && files.length > 0) {
          let projectFiles = new Array();
          let itemFiles = {
              "name": data.name,
              "fileId": files[0].fileId
          }
          projectFiles.push(itemFiles);
          data.projectFiles = projectFiles;
      }

    projectAction
      .Update({ ...data })
      .then((result) => {
        if (result) {
          setOrder("desc");
            setOrderBy("modifiedDate");
          showLoading(false);
          onSuccess();
          ShowNotification(
            viVN.Success.EditProject,
            NotificationMessageType.Success
          );
        }
      })
      .catch((err) => {
        showLoading(false);
        onSuccess();
        ShowNotification(
          viVN.Errors[err.errorType],
          NotificationMessageType.Error
        );
      });
  };

    const onGetData = () => {
        showLoading(true);
        Promise.all([
            getLookupGovernment(),
            getLookupProvince(),
            getLookupProjectStatus(),
            getLookupProjectField(),
            getLookupProjectType(),
            onGetDetailProject(),
        ])
            .then((res) => {

                const [
                    governmentLookup,
                    provinceLookup,
                    projectStatusLookup,
                    projectFieldLookup,
                    projectTypeLookup,
                    projectDetail,
                ] = res;
                governmentLookup &&
                    governmentLookup.content &&
                    setLookupGovernmentModel(governmentLookup.content);
                provinceLookup &&
                    provinceLookup.content &&
                    setLookupProvinceModel(provinceLookup.content);
                projectStatusLookup &&
                    projectStatusLookup.content &&
                    setLookupProjectStatusModel(projectStatusLookup.content);
                projectFieldLookup &&
                    projectFieldLookup.content &&
                    setLookupProjectFieldModel(projectFieldLookup.content);
                projectTypeLookup &&
                    projectTypeLookup.content &&
                    setLookupProjectTypeModel(projectTypeLookup.content);
                showLoading(false);
            })
            .catch((err) => {
                showLoading(false);
            });
    };

    const getLookupProjectType = () => {
        return new Promise((resolve, reject) => {
            projectTypeAction.GetLookup().then(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                }
            );
        });
    };

    const getLookupProjectField = () => {
        return new Promise((resolve, reject) => {
            projectFieldAction.GetLookup().then(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                }
            );
        });
    };

    const getLookupProjectStatus = () => {
        return new Promise((resolve, reject) => {
            projectStatusAction.GetLookup().then(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                }
            );
        });
    };

    const getLookupProvince = () => {
        return new Promise((resolve, reject) => {
            provinceAction.GetLookupProvince().then(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                }
            );
        });
    };

    const getLookupGovernment = () => {
        return new Promise((resolve, reject) => {
            governmentAction.GetLookup().then(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                }
            );
        });
    };

    const onOpenSelectFile = () => {
        setShowAddFile(true);
        setFilesTemp(files);
    };

    const onCloseSelectFile = () => {
        setShowAddFile(false);
        setFiles(filesTemp);
    };

    const onSaveSelectFile = () => {
        setShowAddFile(false);
    };

    const handleChangeIsActive = (event) => {
        event.persist();
        setIsActive(event.target.checked);
    }

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Chỉnh sửa tỉnh</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          {projectModel && (
            <DialogContent className="pt-4 pb-2">
                          <div className="form-group">
                              <div className="row">
                                  <div className="col-12">
                                      <label className="text-dark">
                                          Tên dự án<span className="required"></span>
                                      </label>
                                      <TextField
                                          type="text"
                                          name="name"
                                          className="w-100"
                                          defaultValue={projectModel.name}
                                          autoComplete="off"
                                          inputRef={register({ required: true })}
                                          error={errors.name && errors.name.type === "required"}
                                      />
                                      {errors.name && errors.name.type === "required" && (
                                          <span className="error">Trường này là bắt buộc</span>
                                      )}
                                  </div>
                              </div>
                          </div>
                          <div className="form-group">
                              <div className="row">
                                  <div className="col-12">
                                      <label className="text-dark">
                                          Mô tả<span className="required"></span>
                                      </label>
                                      <textarea
                                          name="description"
                                          rows="3"
                                          ref={register({ required: true, maxLength: 500 })}
                                          defaultValue={projectModel.description}
                                          maxLength="500"
                                          className={
                                              "form-control" +
                                              (errors.description && errors.description.type === "required"
                                                  ? " is-invalid"
                                                  : "")
                                          }
                                      ></textarea>
                                      {errors.description && errors.description.type === "required" && (
                                          <span className="error">Trường này là bắt buộc</span>
                                      )}
                                      {errors.description && errors.description.type === "maxLength" && (
                                          <span className="error">Trường này không quá 500 ký tự</span>
                                      )}
                                  </div>
                              </div>
                          </div>
                          <div className="form-group">
                              <div className="row">
                                  <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                      <label className="text-dark">
                                          Lĩnh vực<span className="required"></span>
                                      </label>
                                      {lookupProjectFieldModel && lookupProjectFieldModel.length > 0 && (
                                          <Autocomplete
                                              options={lookupProjectFieldModel}
                                              getOptionLabel={(option) =>
                                                  typeof option === "string" ? option : option.name
                                              }
                                              value={
                                                  projectField ||
                                                  (lookupProjectFieldModel.some(
                                                      (item) => item.id === projectModel.projectFieldId
                                                  )
                                                      ? lookupProjectFieldModel.find(
                                                          (item) =>
                                                              item.id === projectModel.projectFieldId
                                                      )
                                                      : null)
                                              }
                                              onChange={(event, newValue) => {
                                                  setProjectField(newValue);
                                              }}
                                              disableClearable={true}
                                              renderInput={(params) => (
                                                  <TextField
                                                      {...params}
                                                      name="projectFieldId"
                                                      inputRef={register({ required: true })}
                                                      error={
                                                          errors.projectFieldId &&
                                                          errors.projectFieldId.type === "required"
                                                      }
                                                      variant="outlined"
                                                      size="small"
                                                  />
                                              )}
                                          />
                                      )
                                      }
                                      {errors.projectFieldId &&
                                          errors.projectFieldId.type === "required" && (
                                              <span className="error">Trường này là bắt buộc</span>
                                          )}
                                  </div>
                                  <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                      <label className="text-dark">
                                          Loại dự án<span className="required"></span>
                                      </label>
                                      {lookupProjectTypeModel && lookupProjectTypeModel.length > 0 && (
                                          <Autocomplete
                                              options={lookupProjectTypeModel}
                                              getOptionLabel={(option) =>
                                                  typeof option === "string" ? option : option.name
                                              }
                                              value={
                                                  projectType ||
                                                  (lookupProjectTypeModel.some(
                                                      (item) => item.id === projectModel.projectTypeId
                                                  )
                                                      ? lookupProjectTypeModel.find(
                                                          (item) =>
                                                              item.id === projectModel.projectTypeId
                                                      )
                                                      : null)
                                              }
                                              onChange={(event, newValue) => {
                                                  setProjectType(newValue);
                                              }}
                                              disableClearable={true}
                                              renderInput={(params) => (
                                                  <TextField
                                                      {...params}
                                                      name="projectTypeId"
                                                      inputRef={register({ required: true })}
                                                      error={
                                                          errors.projectTypeId &&
                                                          errors.projectTypeId.type === "required"
                                                      }
                                                      variant="outlined"
                                                      size="small"
                                                  />
                                              )}
                                          />
                                      )
                                      }
                                      {errors.projectTypeId &&
                                          errors.projectTypeId.type === "required" && (
                                              <span className="error">Trường này là bắt buộc</span>
                                          )}
                                  </div>
                              </div>
                          </div>
                          <div className="form-group">
                              <div className="row">
                                  <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                      <label className="text-dark">
                                          Cơ quan ban hành<span className="required"></span>
                                      </label>
                                      {lookupGovernmentModel && lookupGovernmentModel.length > 0 && (
                                          <Autocomplete
                                              options={lookupGovernmentModel}
                                              getOptionLabel={(option) =>
                                                  typeof option === "string" ? option : option.name
                                              }
                                              value={
                                                  government ||
                                                  (lookupGovernmentModel.some(
                                                      (item) => item.id === projectModel.governmentId
                                                  )
                                                      ? lookupGovernmentModel.find(
                                                          (item) =>
                                                              item.id === projectModel.governmentId
                                                      )
                                                      : null)
                                              }
                                              onChange={(event, newValue) => {
                                                  setGovernment(newValue);
                                              }}
                                              disableClearable={true}
                                              renderInput={(params) => (
                                                  <TextField
                                                      {...params}
                                                      name="governmentId"
                                                      inputRef={register({ required: true })}
                                                      error={
                                                          errors.governmentId &&
                                                          errors.governmentId.type === "required"
                                                      }
                                                      variant="outlined"
                                                      size="small"
                                                  />
                                              )}
                                          />
                                      )
                                      }
                                      {errors.governmentId &&
                                          errors.governmentId.type === "required" && (
                                              <span className="error">Trường này là bắt buộc</span>
                                          )}
                                  </div>
                                  <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                      <label className="text-dark">
                                          Trạng thái dự án<span className="required"></span>
                                      </label>
                                      {lookupProjectStatusModel && lookupProjectStatusModel.length > 0 && (
                                          <Autocomplete
                                              options={lookupProjectStatusModel}
                                              getOptionLabel={(option) =>
                                                  typeof option === "string" ? option : option.name
                                              }
                                              value={
                                                  projectStatus ||
                                                  (lookupProjectStatusModel.some(
                                                      (item) => item.id === projectModel.projectStatusId
                                                  )
                                                      ? lookupProjectStatusModel.find(
                                                          (item) =>
                                                              item.id === projectModel.projectStatusId
                                                      )
                                                      : null)
                                              }
                                              onChange={(event, newValue) => {
                                                  setProjectStatus(newValue);
                                              }}
                                              disableClearable={true}
                                              renderInput={(params) => (
                                                  <TextField
                                                      {...params}
                                                      name="projectStatusId"
                                                      inputRef={register({ required: true })}
                                                      error={
                                                          errors.projectStatusId &&
                                                          errors.projectStatusId.type === "required"
                                                      }
                                                      variant="outlined"
                                                      size="small"
                                                  />
                                              )}
                                          />
                                      )
                                      }
                                      {errors.projectStatusId &&
                                          errors.projectStatusId.type === "required" && (
                                              <span className="error">Trường này là bắt buộc</span>
                                          )}
                                  </div>
                              </div>
                          </div>
                          <div className="form-group">
                              <div className="row">
                                  <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                      <label className="text-dark">
                                          Địa phương<span className="required"></span>
                                      </label>
                                      {lookupProvinceModel && lookupProvinceModel.length > 0 && (
                                          <Autocomplete
                                              options={lookupProvinceModel}
                                              getOptionLabel={(option) =>
                                                  typeof option === "string" ? option : option.name
                                              }
                                              value={
                                                  province ||
                                                  (lookupProvinceModel.some(
                                                      (item) => item.id === projectModel.provinceId
                                                  )
                                                      ? lookupProvinceModel.find(
                                                          (item) =>
                                                              item.id === projectModel.provinceId
                                                      )
                                                      : null)
                                              }
                                              onChange={(event, newValue) => {
                                                  setProvince(newValue);
                                              }}
                                              disableClearable={true}
                                              renderInput={(params) => (
                                                  <TextField
                                                      {...params}
                                                      name="provinceId"
                                                      inputRef={register({ required: true })}
                                                      error={
                                                          errors.provinceId &&
                                                          errors.provinceId.type === "required"
                                                      }
                                                      variant="outlined"
                                                      size="small"
                                                  />
                                              )}
                                          />
                                      )
                                      }
                                      {errors.provinceId &&
                                          errors.provinceId.type === "required" && (
                                              <span className="error">Trường này là bắt buộc</span>
                                          )}
                                  </div>
                                  <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                      <label className="text-dark">
                                          Chủ đầu tư
                                      </label>
                                      <TextField
                                          type="text"
                                          name="investor"
                                          defaultValue={projectModel.name}
                                          className="w-100"
                                          autoComplete="off"
                                          inputRef={register()}
                                      />
                                  </div>
                              </div>
                          </div>
                          <div className="form-group">
                              <div className="row">
                                  <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                      <label className="text-dark">
                                          File liên quan<span className="required"></span>
                                      </label>
                                      <div>
                                          <Button
                                              variant="contained"
                                              color="primary"
                                              onClick={onOpenSelectFile}
                                          >
                                              Chọn file
                                </Button>
                                          <TextField
                                              inputRef={register()}
                                              type="text"
                                              name="attachFile"
                                              value={
                                                  (files && files.length > 0 && files[0] && files[0].fileName) || ""
                                              }
                                          />
                                      </div>
                                  </div>
                                  <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                      <label className="text-dark">Kích hoạt</label>
                                      <Checkbox
                                          checked={isActive}
                                          onChange={handleChangeIsActive}
                                          color="primary"
                                          inputProps={{ "aria-label": "secondary checkbox" }}
                                          className="p-0 mt-0 ml-4"
                                      />
                                  </div>
                              </div>
                          </div>
            </DialogContent>
          )}

          <DialogActions className="border-top">
            <Button
              type="button"
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
          </Dialog>
          {
              isShowAddFile && (
                  <Dialog
                      onClose={onCloseSelectFile}
                      open={isShowAddFile}
                      fullWidth={true}
                      maxWidth="md"
                      className="dialog-preview-form"
                  >
                      <DialogTitle disableTypography>
                          <Typography variant="h6">Quản lý file</Typography>
                          <IconButton
                              aria-label="close"
                              className={classes.closeButton}
                              onClick={onCloseSelectFile}
                          >
                              <CloseIcon />
                          </IconButton>
                      </DialogTitle>
                      <DialogContent dividers>
                          <FileManagement
                              files={files}
                              setFiles={setFiles}
                              acceptedFiles={["jpeg", "png", "jpg", "gif", "dwg", "pdf","zip","rar","docx","doc"]}
                          />
                      </DialogContent>

                      <DialogActions>
                          <Button
                              type="button"
                              onClick={onCloseSelectFile}
                              variant="contained"
                              startIcon={<CloseIcon />}
                          >
                              Hủy
                                    </Button>
                          {files && files.length > 0 && (
                              <Button
                                  type="button"
                                  color="primary"
                                  variant="contained"
                                  startIcon={<SaveIcon />}
                                  onClick={onSaveSelectFile}
                              >
                                  Lưu
                              </Button>
                          )}
                      </DialogActions>
                  </Dialog>
              )
          }
      </div>


  );
}
