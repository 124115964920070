import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import * as config from "../../../common/config"

const service = new Service();
export const GetListAll = (pageIndex, pageSize = config.Configs.DefaultPageSize, sorting, keyword, governmentId, provinceId, projectFieldId, projectTypeId, projectStatusId, isActive) => {
    const params = new URLSearchParams();
    keyword && params.append('keyword', keyword);
    governmentId && params.append('governmentId', governmentId);
    provinceId && params.append('provinceId', provinceId);
    projectFieldId && params.append('projectFieldId', projectFieldId);
    projectTypeId && params.append('projectTypeId', projectTypeId);
    projectStatusId && params.append('projectStatusId', projectStatusId);
    isActive && params.append('isActive', isActive);
    params.append('sorting', sorting);
    params.append('pageIndex', pageIndex ? pageIndex : 1);
    params.append('pageSize', pageSize);
    return service.get(ApiUrl.Project_GetListAll, params).then((res) => { return res }).catch(err => { throw err });
}
export const GetDetai = (id) => {
    return service.get(ApiUrl.Project_GetDetail.replace("{id}", id)).then(res => { return res }).catch(err => { throw err });
}

export const Create = (body) => {
    return service.post(ApiUrl.Project_Create, body).then(res => { return res }).catch(err => { throw err });
}

export const Update = (body) => {
    return service.post(ApiUrl.Project_Update, body).then(res => { return res }).catch(err => { throw err });
}

export const Delete = (id) => {
    return service.postParams(ApiUrl.Project_Delete.replace("{id}", id)).then(res => { return res }).catch(err => { throw err });
}

export const ChangeActive = (id) => {
    return service.postParams(ApiUrl.Project_Change_Active.replace("{id}", id)).then(res => { return res }).catch(err => { throw err });
}