import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import "suneditor/dist/css/suneditor.min.css";
import { useForm } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    TextField,
} from "@material-ui/core";

function FormAutocomplete(props) {

    const { 
        label,
        optionsdata,
        required,
        setAutocompleteValue,
        name,
        multiple
    } = props;

    const { register, setError, errors, clearErrors, setValue } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    return (
        optionsdata && optionsdata.length > 0 && (
        <>
            <label className="text-dark">
                {label}
            </label>
                {
                    required && <span className="required"></span>
                }
            <Autocomplete
                    multiple={multiple}
                    options={optionsdata}
                getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                }
                onChange={(event, newValue) => {
                    setAutocompleteValue(newValue);
                    if (required) {
                        if (newValue.length > 0) {
                            setValue("autocompleteValue", "11");
                            clearErrors("autocompleteValue");
                        } else {
                            setValue("autocompleteValue", "");
                            setError("autocompleteValue", { type: "required" });
                        }
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={
                            required && errors.autocompleteValue &&
                            errors.autocompleteValue.type === "required"
                        }
                        variant="outlined"
                        size="small"
                    />
                )}
            />

                {
                    required && (
                        <>
                            <TextField
                                name="autocompleteValue"
                                name="autocompleteValue"
                                hidden
                                inputRef={register({ required: true })}
                            />
                            {
                                errors.autocompleteValue &&
                                errors.autocompleteValue.type === "required" && (
                                    <span className="error">Trường này là bắt buộc</span>
                                )
                            }
                        </>
                    )
                }
            </>
            )
    )
}

const mapStateToProps = state => ({
});

export default connect(mapStateToProps)(FormAutocomplete);