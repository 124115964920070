import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

//--- Material Control
import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  makeStyles,
  Typography,
    IconButton,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

//--- Notifications
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";

//--- Action
import * as categoryAction from "../../../redux/store/planning-category-management/planning-category.store";
import * as planningAction from "../../../redux/store/planning/planning.store";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function EditCategoryManagement(props) {
  const classes = useStyles();

  const {
    isOpen,
    onClose,
    onSuccess,
    categoryId,
    setOrder,
    setOrderBy,
    showLoading,
  } = props;

    const [categoryModel, setCategoryModel] = useState();
    const [lookupParentCategory, setLookupParentCategory] = useState([]);
    const [parentCategory, setParentCategory] = useState(null);
    const [lookupPlanning, setLookupPlanning] = useState([]);
    const [planning, setPlanning] = useState(null);
    const [isActive, setIsActive] = useState(true);

  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

    useEffect(() => {
        showLoading(true);
        onGetData();
  }, []);

    const onGetDetailCategory = (id = categoryId) => {
        return new Promise((resolve, reject) => {
            categoryAction.GetDetai(id).then(
                (res) => {
                    if (!res || !res.content) {
                        reject(res);
                    }

                    setCategoryModel(res.content);
                    setIsActive(res.content?.isActive == 1 ? true : false);
                    getLookupPlanning(res.content.id);
                    resolve(res);
                },
                (err) => {
                    reject(err);
                    ShowNotification(
                        viVN.Errors[(err && err.errorType) || "UnableHandleException"],
                        NotificationMessageType.Error
                    );
                }
            );
        });
    };

  const onSubmit = (data) => {
    if (!data) {
      return;
      }
      showLoading(true);
      let body = {
          id: categoryId,
          name: data.name,
          isActive: isActive,
          parentId: (parentCategory && parentCategory.id) || categoryModel.parentId || null,
          defaultPlanningId: (planning && planning.id) || categoryModel.defaultPlanningId || null,
      };

    categoryAction
        .Update(body)
      .then((result) => {
        if (result) {
          setOrder("asc");
            setOrderBy("Code");
          showLoading(false);
          onSuccess();
          ShowNotification(
            viVN.Success.EditCategory,
            NotificationMessageType.Success
          );
        }
      })
      .catch((err) => {
        showLoading(false);
        onSuccess();
        ShowNotification(
          viVN.Errors[err.errorType],
          NotificationMessageType.Error
        );
      });
  };

    const onGetData = () => {
        showLoading(true);
        Promise.all([
            getLookupParentCategory(),
            onGetDetailCategory(),
        ])
            .then((res) => {

                const [
                    parentCategoryLookup,
                    categoryDetail,
                ] = res;
                parentCategoryLookup &&
                    parentCategoryLookup.content &&
                    setLookupParentCategory(parentCategoryLookup.content);
                showLoading(false);
            })
            .catch((err) => {
                showLoading(false);
            });
    };

    const getLookupParentCategory = () => {
        return new Promise((resolve, reject) => {
            categoryAction.GetLookup().then(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                }
            );
        });
    };

    const getLookupPlanning = (categoryId) => {
        return new Promise((resolve, reject) => {
            planningAction.GetLookUpPlanning(categoryId).then(
                (res) => {
                    setLookupPlanning(res.content);
                },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                }
            );
        });
    };

    const handleChangeIsActive = (event) => {
        event.persist();
        setIsActive(event.target.checked);
    }

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Chỉnh sửa tỉnh</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          {categoryModel && (
            <DialogContent className="pt-4 pb-2">
                          <div className="form-group">
                              <div className="row">
                                  <div className="col-12">
                                      <label className="text-dark">
                                          Tên loại QH<span className="required"></span>
                                      </label>
                                      <TextField
                                          type="text"
                                          name="name"
                                          className="w-100"
                                          defaultValue={categoryModel.name}
                                          autoComplete="off"
                                          inputRef={register({ required: true })}
                                          error={errors.name && errors.name.type === "required"}
                                      />
                                      {errors.name && errors.name.type === "required" && (
                                          <span className="error">Trường này là bắt buộc</span>
                                      )}
                                  </div>
                              </div>
                          </div>
                          <div className="form-group">
                              <div className="row">
                                  <div className="col-12">
                                      <label className="text-dark">
                                          Loại QH cấp trên<span className="required"></span>
                                      </label>
                                      {lookupParentCategory && lookupParentCategory.length > 0 && (
                                          <Autocomplete
                                              options={lookupParentCategory}
                                              getOptionLabel={(option) =>
                                                  typeof option === "string" ? option : option.name
                                              }
                                              value={
                                                  parentCategory ||
                                                  (lookupParentCategory.some(
                                                      (item) => item.id === categoryModel.parentId
                                                  )
                                                      ? lookupParentCategory.find(
                                                          (item) =>
                                                              item.id === categoryModel.parentId
                                                      )
                                                      : null)
                                              }
                                              onChange={(event, newValue) => {
                                                  setParentCategory(newValue);
                                              }}
                                              disableClearable={true}
                                              renderInput={(params) => (
                                                  <TextField
                                                      {...params}
                                                      name="parentCategoryId"
                                                      inputRef={register()}
                                                      variant="outlined"
                                                      size="small"
                                                  />
                                              )}
                                          />
                                      )
                                      }
                                  </div>
                              </div>
                          </div>
                          <div className="form-group">
                              {lookupPlanning && lookupPlanning.length > 0 && (
                              <div className="row">
                                  <div className="col-12">
                                      <label className="text-dark">
                                          Quy hoặc mặc định
                                      </label>
                                     
                                          <Autocomplete
                                              options={lookupPlanning}
                                              getOptionLabel={(option) =>
                                                  typeof option === "string" ? option : option.name
                                              }
                                              value={
                                                  planning ||
                                                  (lookupPlanning.some(
                                                      (item) => item.id === categoryModel.defaultPlanningId
                                                  )
                                                      ? lookupPlanning.find(
                                                          (item) =>
                                                              item.id === categoryModel.defaultPlanningId
                                                      )
                                                      : null)
                                              }
                                              onChange={(event, newValue) => {
                                                  setPlanning(newValue);
                                              }}
                                              disableClearable={true}
                                              renderInput={(params) => (
                                                  <TextField
                                                      {...params}
                                                      name="parentCategoryId"
                                                      inputRef={register()}
                                                      variant="outlined"
                                                      size="small"
                                                  />
                                              )}
                                          />
                                      
                                      
                                  </div>
                              </div>)
                              }
                          </div>
                          <div className="form-group">
                              <div className="row">
                                  <div className="col-12 col-md-4 col-lg-4">
                                      <label className="text-dark">Kích hoạt</label>
                                      <Checkbox
                                          checked={isActive}
                                          onChange={handleChangeIsActive}
                                          color="primary"
                                          inputProps={{ "aria-label": "secondary checkbox" }}
                                          className="p-0 mt-0 ml-4"
                                      />
                                  </div>
                              </div>
                          </div>
            </DialogContent>
          )}

          <DialogActions className="border-top">
            <Button
              type="button"
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
