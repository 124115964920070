import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Configs } from "../../../common/config";

//--- Material Table
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import dateformat from "dateformat";

//--- Material Icon
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

//--- Material Control
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { UrlCollection } from "../../../common/url-collection";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    maxHeight: window.outerHeight - 365,
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const headCells = [
  { id: "name", hideSortIcon: true, label: "Tên Quy Hoạch" },
  { id: "provinceName", hideSortIcon: true, label: "Tỉnh/Thành" },
  { id: "planning.name", hideSortIcon: true, label: "Thời kỳ lập quy hoạch" },
  { id: "isActive", hideSortIcon: true, label: "Trạng thái" },
];
const statues = [
  {
    value: 0,
    name: "---không chọn---",
  },
  {
    value: 1,
    name: "Chưa nộp",
  },
  {
    value: 2,
    name: "Đã nộp",
  },
  {
    value: 3,
    name: "Đã tiếp nhận",
  },
  {
    value: 4,
    name: "Đã phê duyệt",
  },
  {
    value: 5,
    name: "Đã cập nhật lên hệ thống",
  },
];
function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            className="pt-3 pb-3 text-nowrap"
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={headCell.hideSortIcon ? true : false}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function ListCategoryManagement(props) {
  const {
    editAction,
    categoryModels,
    totalItemCount,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    GetListCategory,
    order,
    page,
    rowsPerPage,
    orderBy,
    deleteAction,
    updateItem
  } = props;
  const [recordsStatus, setRecordsStatus] = useState(statues[0]);
  //--- Config table
  const classes = useStyles();

  //--- Handle sort, change page, change row per page
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    GetListCategory(page + 1, rowsPerPage);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    GetListCategory(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    GetListCategory(1, event.target.value);
  };

  // const emptyRows =
  //  rowsPerPage -
  //  Math.min(rowsPerPage, categoryModels.length - page * rowsPerPage);
  
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} size="small" stickyHeader>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={categoryModels?.length}
            />
            <TableBody>
              {categoryModels && categoryModels?.length > 0 ? (
                categoryModels.map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell>
                        <a href={`${UrlCollection.PlanningDetail}/${row.id}`}>{row.name}</a>
                      </TableCell>
                      <TableCell>{row.provinceName}</TableCell>
                      <TableCell>
                        {row.fromYear} - {row.toYear}
                      </TableCell>
                      <TableCell>
                        <FormControl variant="outlined">
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            className="input-select"
                            name="startYear"
                            style={{ width: "100%" }}
                            disabled={row.recordsStatus === 1}
                            value={row.recordsStatus}
                            onChange={(e) => updateItem(row.id, e.target.value)}
                          >
                            {statues.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow hover tabIndex={-1}>
                  <TableCell colSpan={5} className="text-center">
                    Không có dữ liệu
                  </TableCell>
                </TableRow>
              )}
              {
                //emptyRows > 0 && (
                //  <TableRow style={{ height: 0 }}>
                //    <TableCell
                //      colSpan={5}
                //      style={{ padding: 0, borderBottom: 0 }}
                //    />
                //  </TableRow>
                //                )
              }
            </TableBody>
          </Table>
        </TableContainer>

        {totalItemCount && totalItemCount > 0 ? (
          <TablePagination
            rowsPerPageOptions={Configs.DefaultPageSizeOption}
            component="div"
            count={totalItemCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={"Số hàng mỗi trang"}
            labelDisplayedRows={({ from, to, count }) => {
              return "" + from + "-" + to + " trong " + count || "";
            }}
          />
        ) : (
          ""
        )}
      </Paper>
    </div>
  );
}
