/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ApiUrl } from "../../../api/api-url";

//--- Action
import * as videosAction from "../../../redux/store/videos/videos.store";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import * as viVN from "../../../language/vi-VN.json";

//--- Material Control
import {
  DialogActions,
  TextareaAutosize,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  makeStyles,
  Typography,
  IconButton,
  Select,
  MenuItem,
  Checkbox,
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

//--- Notifications
import FileInputComponent from "../../../components/file-input/file-input.view";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import {
  NotificationMessageType,
  APIUrlDefault,
  MaxSizeImageUpload,
} from "../../../utils/configuration";
import * as appActions from "../../../core/app.store";
import FileManagement from "../../../components/file_management/file_management";
//--- Styles
import "../videos.scss";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function EditVideos(props) {
  const classes = useStyles();

  const {
    isOpen,
    onClose,
    onSuccess,
    videosId,

    categoryId,
    videosDetail,
    GetListAll,
    Update,
  } = props;

  const [videosModel, setVideosModel] = useState();
  const [status, setStatus] = useState(true);
  const [isHot, setIsHot] = useState(true);
  const [imageUrl, setImageUrl] = useState("");
  const [content, setContent] = useState();
  const [isDeleteImage, setIsDeleteImage] = useState(false);
  const [isShow, setShow] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesTemp, setFilesTemp] = useState([]);
  const urlUploadImage = APIUrlDefault + ApiUrl.UrlUploadFromEditor;

  useEffect(() => {
    setVideosModel(videosDetail);
    setImageUrl(videosDetail?.image_Url);
    setStatus(videosDetail?.status == 1 ? true : false);
    setIsHot(videosDetail?.isHot == 1 ? true : false);
    setContent(videosDetail?.content);
    setFiles(videosDetail?.files ? [videosDetail.files] : []);
  }, [videosDetail]);

  const { register, handleSubmit, setError, errors, clearErrors, setValue } =
    useForm({
      mode: "all",
      reValidateMode: "onBlur",
    });

  const onSubmit = (data) => {
    if (!data) {
      return;
    }

    let formData = new FormData();
    formData.append("id", videosId);
    formData.append("xmin", videosModel.xmin);
    data.title && formData.append("title", data.title);
    data.linkYoutube && formData.append("linkYoutube", data.linkYoutube);
    formData.append("status", status ? 1 : 0);
    formData.append("isHot", isHot ? 1 : 0);
    formData.append("isDeleteImage", isDeleteImage ? 1 : 0);
    formData.append("isFeature", 0);
    formData.append("isHomePage", 0);
    if (Update(formData)) onSuccess();
  };


  const handleChangeStatus = (event) => {
    event.persist();
    setStatus(event.target.checked);
  };

  const handleChangeHot = (event) => {
    event.persist();
    setIsHot(event.target.checked);
  };

  const onCloseSelectFile = () => {
    setShow(false);
    setFiles(filesTemp);
  };

  const onSaveSelectFile = () => {
    setShow(false);
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="lg">
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Chỉnh sửa Video</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          {videosModel && (
            <DialogContent className="pt-4 pb-2">
              <div className="form-group">
                <label className="text-dark">
                  Tiêu đề<span className="required"></span>
                </label>
                <TextField
                  inputRef={register({ required: true, maxLength: 150 })}
                  name="title"
                  error={errors.title && errors.title.type == "required"}
                  fullWidth
                  type="text"
                  className="form-control"
                  inputProps={{ maxLength: 150 }}
                  defaultValue={videosModel.title}
                  onChange={(e) => setValue("title", e.target.value)}
                />
                {errors.title && errors.title.type == "required" && (
                  <span className="error">Trường này là bắt buộc</span>
                )}
                {errors.title && errors.title.type == "maxLength" && (
                  <span className="error">Trường này không quá 150 ký tự</span>
                )}
              </div>
              <div className="form-group">
                <label className="text-dark">Thêm video từ Youtube <span className="required"></span></label>
                <TextField
                  name={"linkYoutube"}
                  placeholder={"https://www.youtube.com/watch?v=uEP92B9zB5c"}
                  error={
                    errors.linkYoutube && errors.linkYoutube.type === "pattern"
                  }
                  defaultValue={videosModel?.linkYoutube}
                  fullWidth
                  type="text"
                  className="form-control"
                  inputRef={register({
                    required: true,
                    pattern:
                  /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/,
                  })}
                />
                {errors.linkYoutube &&
                  errors.linkYoutube.type === "pattern" && (
                    <span className="error">Trường này chưa phải là URL</span>
                  )}
                    {errors.linkYoutube && errors.linkYoutube.type == "required" && (
                  <span className="error">Trường này là bắt buộc</span>
                )}
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col-12 col-md-4 col-lg-4">
                    <label className="text-dark">Kích hoạt</label>
                    <Checkbox
                      checked={status}
                      onChange={handleChangeStatus}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      className="p-0 mt-0 ml-4"
                    />
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <label className="text-dark">Tin nóng</label>
                    <Checkbox
                      checked={isHot}
                      onChange={handleChangeHot}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      className="p-0 mt-0 ml-4"
                    />
                  </div>
                </div>
              </div>
            </DialogContent>
          )}

          <DialogActions className="border-top">
            <Button
              type="button"
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {isShow && (
        <Dialog
          onClose={onCloseSelectFile}
          open={isShow}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={files}
              setFiles={setFiles}
              acceptedFiles={["jpeg", "png", "jpg", "gif"]}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {files && files.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  videosDetail: state.initVideos.videosDetail,
  categoryId: state.initVideos.categoryId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetListAll: videosAction.GetList,
      Update: videosAction.Update,
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditVideos);
