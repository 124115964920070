import React, { Component, useEffect, useState } from 'react';
import * as clientSettingAction from "../../redux/store/client_setting/client_setting.store";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { useForm } from "react-hook-form";

function Editor(props) {

    const { imageAccept,
        imageUploadSizeLimit,
        imageUploadUrl,
        onChangeContent,
        height,
        required,
        defaultContent,
    } = props;

    const { register, handleSubmit, setError, errors, clearErrors, setValue } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const onChange = (editorContent) => {
        if (required) {
            clearErrors(["editorContent"]);
            if (editorContent === "<p><br></p>") {
                setError("editorContent", { type: "required" });
                onChangeContent("");
            } else {
                clearErrors("editorContent");
                onChangeContent(editorContent);
            }
        }
        else
            onChangeContent(editorContent);
    };

    return (
        <div className="form-group">
            <label className="text-dark">Nội dung</label>
            <SunEditor
                enableToolbar={true}
                showToolbar={true}
                imageAccept={imageAccept}
                imageUploadSizeLimit={imageUploadSizeLimit}
                videoFileInput={false}
                imageUploadUrl={imageUploadUrl}
                setContents={defaultContent}
                setOptions={{
                    height: height || 500,
                    buttonList: [
                        [
                            "undo",
                            "redo",
                            "font",
                            "fontSize",
                            "formatBlock",
                            "paragraphStyle",
                            "blockquote",
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                            "fontColor",
                            "hiliteColor",
                            "textStyle",
                            "removeFormat",
                            "outdent",
                            "indent",
                            "align",
                            "horizontalRule",
                            "list",
                            "lineHeight",
                            "table",
                            "link",
                            "image",
                            "video",
                            "audio",
                            "fullScreen",
                            "showBlocks",
                            "codeView",
                        ],
                    ],
                }}
                onChange={onChange}
                onBlur={(event, editorContents) => onChange(editorContents)}

            />
        </div>
    )
}

const mapStateToProps = state => ({
});

export default connect(mapStateToProps)(Editor);