import React, {useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import '../search.scss';
//--- Material Control
import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
    makeStyles,
  Checkbox
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";

//--- Action
import * as projectAction from "../../../redux/store/project-management/project.store";
import * as provinceAction from "../../../redux/store/province-management/province.store";
import * as governmentAction from "../../../redux/store/government-management/government.store";
import * as projectStatusAction from "../../../redux/store/project-status-management/project-status.store";
import * as projectTypeAction from "../../../redux/store/project-management/project-type.store";
import * as projectFieldAction from "../../../redux/store/project-management/project-field.store";
import FileManagement from "../../../components/file_management/file_management";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function AddProjectManagement(props) {
  const classes = useStyles();
  const {
    isOpen,
    onClose,
    onSuccess,
    GetListProject,
    rowsPerPage,
    showLoading,
  } = props;

  const { register, handleSubmit, errors } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

    const [lookupProjectFieldModel, setLookupProjectFieldModel] = useState([]);
    const [projectField, setProjectField] = useState(null);
    const [lookupProjectTypeModel, setLookupProjectTypeModel] = useState([]);
    const [projectType, setProjectType] = useState(null);
    const [lookupProjectStatusModel, setLookupProjectStatusModel] = useState([]);
    const [projectStatus, setProjectStatus] = useState(null);
    const [lookupGovernmentModel, setLookupGovernmentModel] = useState([]);
    const [government, setGovernment] = useState(null);
    const [lookupProvinceModel, setLookupProvinceModel] = useState([]);
    const [province, setProvince] = useState(null);
    const [address, setAddress] = useState("");
    const [coordinate, setCoordinate] = useState("");
    const [isActive, setIsActive] = useState(true);
    const [isShowAddFile, setShowAddFile] = useState(false);
    const [files, setFiles] = useState([]);
    const [filesTemp, setFilesTemp] = useState([]);

    useEffect(() => {
        onGetData();
    }, []);

    const onGetData = () => {
        showLoading(true);
        Promise.all([
            getLookupGovernment(),
            getLookupProvince(),
            getLookupProjectStatus(),
            getLookupProjectField(),
            getLookupProjectType(),
        ])
            .then((res) => {
                
                const [
                    governmentLookup,
                    provinceLookup,
                    projectStatusLookup,
                    projectFieldLookup,
                    projectTypeLookup,
                ] = res;
                governmentLookup &&
                    governmentLookup.content &&
                    setLookupGovernmentModel(governmentLookup.content);
                provinceLookup &&
                    provinceLookup.content &&
                    setLookupProvinceModel(provinceLookup.content);
                projectStatusLookup &&
                    projectStatusLookup.content &&
                    setLookupProjectStatusModel(projectStatusLookup.content);
                projectFieldLookup &&
                    projectFieldLookup.content &&
                    setLookupProjectFieldModel(projectFieldLookup.content);
                projectTypeLookup &&
                    projectTypeLookup.content &&
                    setLookupProjectTypeModel(projectTypeLookup.content);
                showLoading(false);
            })
            .catch((err) => {
                showLoading(false);
            });
    };

    const getLookupProjectType = () => {
        return new Promise((resolve, reject) => {
            projectTypeAction.GetLookup().then(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                }
            );
        });
    };

    const getLookupProjectField = () => {
        return new Promise((resolve, reject) => {
            projectFieldAction.GetLookup().then(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                }
            );
        });
    };

    const getLookupProjectStatus = () => {
        return new Promise((resolve, reject) => {
            projectStatusAction.GetLookup().then(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                }
            );
        });
    };

    const getLookupProvince = () => {
        return new Promise((resolve, reject) => {
            provinceAction.GetLookupProvince().then(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                }
            );
        });
    };

    const getLookupGovernment = () => {
        return new Promise((resolve, reject) => {
            governmentAction.GetLookup().then(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                }
            );
        });
    };

  const onSubmit = (data) => {
    if (!data) {
      return;
    }
      showLoading(true);
      data.projectFieldId = projectField.id;
      data.projectTypeId = projectType.id;
      data.projectStatusId = projectStatus.id;
      data.governmentId = government.id;
      data.provinceId = province.id;
      data.isActive = isActive;

      if (files && files.length > 0) {
          let projectFiles = new Array();
          let itemFiles = {
              "name": data.name,
              "fileId": files[0].fileId
          }
          projectFiles.push(itemFiles);
          data.projectFiles = projectFiles;
      }

    projectAction
      .Create(data)
      .then((result) => {
        if (result && result.content && result.content.status === true) {
          GetListProject(1, rowsPerPage);
          showLoading(false);
          onSuccess();
          ShowNotification(
            viVN.Success.AddProject,
            NotificationMessageType.Success
          );
        }
      })
      .catch((err) => {
        showLoading(false);
        ShowNotification(
          viVN.Errors[err.errorType],
          NotificationMessageType.Error
        );
      });
  };

    const handleSelectAddress = (address) => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                //console.log('Success', latLng);
                console.log(latLng.lat + "," + latLng.lng);
                setCoordinate(latLng.lat + "," + latLng.lng);
            })
            .catch(error => {
                console.error('Error', error);
                setCoordinate("");
            });
    };

    const handlePlacesError = (status, clearSuggestions) => {
        clearSuggestions();
    };

    const handleChangeIsActive = (event) => {
        event.persist();
        setIsActive(event.target.checked);
    }

    const onOpenSelectFile = () => {
        setShowAddFile(true);
        setFilesTemp(files);
    };

    const onCloseSelectFile = () => {
        setShowAddFile(false);
        setFiles(filesTemp);
    };

    const onSaveSelectFile = () => {
        setShowAddFile(false);
    };

    const classnames = (...args) => {
        const classes = [];
        args.forEach((arg) => {
            if (typeof arg === "string") {
                classes.push(arg);
            } else if (typeof arg === "object" && arg !== null) {
                Object.keys(arg).forEach((key) => {
                    if (arg[key]) {
                        classes.push(key);
                    }
                });
            } else {
                throw new Error(
                    "`classnames` only accepts string or object as arguments"
                );
            }
        });

        return classes.join(" ");
    };

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Thêm tỉnh</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <DialogContent className="pt-4 pb-2">
            <div className="form-group">
              <div className="row">
                <div className="col-12">
                  <label className="text-dark">
                    Tên dự án<span className="required"></span>
                  </label>
                  <TextField
                    type="text"
                    name="name"
                    className="w-100"
                    autoComplete="off"
                    inputRef={register({ required: true })}
                    error={errors.name && errors.name.type === "required"}
                  />
                  {errors.name && errors.name.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>
              </div>
            </div>
                      <div className="form-group">
                          <div className="row">
                              <div className="col-12">
                                  <label className="text-dark">
                                      Mô tả<span className="required"></span>
                                  </label>
                                  <textarea
                                      name="description"
                                      rows="3"
                                      ref={register({ required: true, maxLength: 500 })}
                                      maxLength="500"
                                      className={
                                          "form-control" +
                                          (errors.description && errors.description.type === "required"
                                              ? " is-invalid"
                                              : "")
                                      }
                                  ></textarea>
                                  {errors.description && errors.description.type === "required" && (
                                      <span className="error">Trường này là bắt buộc</span>
                                  )}
                                  {errors.description && errors.description.type === "maxLength" && (
                                      <span className="error">Trường này không quá 500 ký tự</span>
                                  )}
                              </div>
                          </div>
                      </div>
                      <div className="form-group">
                          <div className="row">
                              <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                  <label className="text-dark">
                                      Lĩnh vực<span className="required"></span>
                                  </label>
                                  {lookupProjectFieldModel && lookupProjectFieldModel.length > 0 && (
                                  <Autocomplete
                                      options={lookupProjectFieldModel}
                                      getOptionLabel={(option) =>
                                          typeof option === "string" ? option : option.name
                                      }
                                      value={projectField}
                                      onChange={(event, newValue) => {
                                          setProjectField(newValue);
                                      }}
                                      disableClearable={true}
                                      renderInput={(params) => (
                                          <TextField
                                              {...params}
                                              name="projectFieldId"
                                              inputRef={register({ required: true })}
                                              error={
                                                  errors.projectFieldId &&
                                                  errors.projectFieldId.type === "required"
                                              }
                                              variant="outlined"
                                              size="small"
                                          />
                                      )}
                                      />
                                  )
                                  }
                                  {errors.projectFieldId &&
                                      errors.projectFieldId.type === "required" && (
                                          <span className="error">Trường này là bắt buộc</span>
                                      )}
                              </div>
                              <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                  <label className="text-dark">
                                      Loại dự án<span className="required"></span>
                                  </label>
                                  {lookupProjectTypeModel && lookupProjectTypeModel.length > 0 && (
                                      <Autocomplete
                                          options={lookupProjectTypeModel}
                                          getOptionLabel={(option) =>
                                              typeof option === "string" ? option : option.name
                                          }
                                          value={projectType}
                                          onChange={(event, newValue) => {
                                              setProjectType(newValue);
                                          }}
                                          disableClearable={true}
                                          renderInput={(params) => (
                                              <TextField
                                                  {...params}
                                                  name="projectTypeId"
                                                  inputRef={register({ required: true })}
                                                  error={
                                                      errors.projectTypeId &&
                                                      errors.projectTypeId.type === "required"
                                                  }
                                                  variant="outlined"
                                                  size="small"
                                              />
                                          )}
                                      />
                                  )
                                  }
                                  {errors.projectTypeId &&
                                      errors.projectTypeId.type === "required" && (
                                          <span className="error">Trường này là bắt buộc</span>
                                      )}
                              </div>
                          </div>
                      </div>
                      <div className="form-group">
                          <div className="row">
                              <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                  <label className="text-dark">
                                      Cơ quan ban hành<span className="required"></span>
                                  </label>
                                  {lookupGovernmentModel && lookupGovernmentModel.length > 0 && (
                                      <Autocomplete
                                          options={lookupGovernmentModel}
                                          getOptionLabel={(option) =>
                                              typeof option === "string" ? option : option.name
                                          }
                                          value={government}
                                          onChange={(event, newValue) => {
                                              setGovernment(newValue);
                                          }}
                                          disableClearable={true}
                                          renderInput={(params) => (
                                              <TextField
                                                  {...params}
                                                  name="governmentId"
                                                  inputRef={register({ required: true })}
                                                  error={
                                                      errors.governmentId &&
                                                      errors.governmentId.type === "required"
                                                  }
                                                  variant="outlined"
                                                  size="small"
                                              />
                                          )}
                                      />
                                  )
                                  }
                                  {errors.governmentId &&
                                      errors.governmentId.type === "required" && (
                                          <span className="error">Trường này là bắt buộc</span>
                                      )}
                              </div>
                              <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                  <label className="text-dark">
                                      Trạng thái dự án<span className="required"></span>
                                  </label>
                                  {lookupProjectStatusModel && lookupProjectStatusModel.length > 0 && (
                                      <Autocomplete
                                          options={lookupProjectStatusModel}
                                          getOptionLabel={(option) =>
                                              typeof option === "string" ? option : option.name
                                          }
                                          value={projectStatus}
                                          onChange={(event, newValue) => {
                                              setProjectStatus(newValue);
                                          }}
                                          disableClearable={true}
                                          renderInput={(params) => (
                                              <TextField
                                                  {...params}
                                                  name="projectStatusId"
                                                  inputRef={register({ required: true })}
                                                  error={
                                                      errors.projectStatusId &&
                                                      errors.projectStatusId.type === "required"
                                                  }
                                                  variant="outlined"
                                                  size="small"
                                              />
                                          )}
                                      />
                                  )
                                  }
                                  {errors.projectStatusId &&
                                      errors.projectStatusId.type === "required" && (
                                          <span className="error">Trường này là bắt buộc</span>
                                      )}
                              </div>
                          </div>
                      </div>
                      <div className="form-group">
                          <div className="row">
                              <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                  <label className="text-dark">
                                      Địa phương<span className="required"></span>
                                  </label>
                                  {lookupProvinceModel && lookupProvinceModel.length > 0 && (
                                      <Autocomplete
                                          options={lookupProvinceModel}
                                          getOptionLabel={(option) =>
                                              typeof option === "string" ? option : option.name
                                          }
                                          value={province}
                                          onChange={(event, newValue) => {
                                              setProvince(newValue);
                                          }}
                                          disableClearable={true}
                                          renderInput={(params) => (
                                              <TextField
                                                  {...params}
                                                  name="provinceId"
                                                  inputRef={register({ required: true })}
                                                  error={
                                                      errors.provinceId &&
                                                      errors.provinceId.type === "required"
                                                  }
                                                  variant="outlined"
                                                  size="small"
                                              />
                                          )}
                                      />
                                  )
                                  }
                                  {errors.provinceId &&
                                      errors.provinceId.type === "required" && (
                                          <span className="error">Trường này là bắt buộc</span>
                                      )}
                              </div>
                              <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                  <label className="text-dark">
                                      Chủ đầu tư<span className="required"></span>
                                  </label>
                                  <TextField
                                      type="text"
                                      name="investor"
                                      className="w-100"
                                      autoComplete="off"
                                      inputRef={register()}
                                  />
                              </div>
                          </div>
                      </div>
                      <div className="form-group">
                          <div className="row">
                              <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                  <label className="text-dark">
                                      File liên quan<span className="required"></span>
                                  </label>
                                  {!isShowAddFile &&
                                      files &&
                                      files.length > 0 &&
                                      files.map((item) => (
                                          <div key={item.fileName} style={{ width: "150px" }}>
                                              {
                                                  //<img
                                                  //    src={APIUrlDefault + item.filePreview}
                                                  //    alt={item.fileName}
                                                  //    className="img-fluid mb-2"
                                                  //    style={{
                                                  //        width: "auto",
                                                  //        height: "auto",
                                                  //        maxWidth: "100%",
                                                  //        maxHeight: "100%",
                                                  //    }}
                                                  ///>
                                              }

                                          </div>
                                      ))}
                                  <div>
                                      <Button
                                          variant="contained"
                                          color="primary"
                                          onClick={onOpenSelectFile}
                                      >
                                          Chọn file
                                </Button>
                                      <TextField
                                          inputRef={register()}
                                          type="text"
                                          name="attachFile"
                                          value={
                                              (files && files.length > 0 && files[0].fileName) || ""
                                          }
                                      />
                                  </div>
                              </div>
                              <div className="col-6 col-md-6 col-lg-6 col-sm-12">
                                  <label className="text-dark">Kích hoạt</label>
                                  <Checkbox
                                      checked={isActive}
                                      onChange={handleChangeIsActive}
                                      color="primary"
                                      inputProps={{ "aria-label": "secondary checkbox" }}
                                      className="p-0 mt-0 ml-4"
                                  />
                              </div>
                          </div>
                      </div>
                      {
                          //<div className="form-group">
                          //    <div className="row">
                          //        <div className="col-12">
                          //            <label className="text-dark">
                          //                Địa chỉ dự án<span className="required"></span>
                          //            </label>
                          //            <PlacesAutocomplete
                          //                value={address}
                          //                onChange={setAddress}
                          //                onSelect={handleSelectAddress}
                          //                onError={handlePlacesError}
                          //                clearItemsOnError={true}
                          //            >
                          //                {({
                          //                    getInputProps,
                          //                    suggestions,
                          //                    getSuggestionItemProps,
                          //                    loading,
                          //                }) => (
                          //                        <div className="map__search-bar-container">
                          //                            <div className="map__search-input-container">
                          //                                <input
                          //                                    {...getInputProps({
                          //                                        placeholder: "Nhập địa điểm tra cứu",
                          //                                        className: "input-search",
                          //                                    })}
                          //                                />
                          //                            </div>
                          //                            {suggestions.length > 0 && (
                          //                                <div className="map__autocomplete-container">
                          //                                    {suggestions.map((suggestion, index) => {
                          //                                        const className = classnames(
                          //                                            "map__suggestion-item",
                          //                                            {
                          //                                                "map__suggestion-item--active":
                          //                                                    suggestion.active,
                          //                                            }
                          //                                        );
                          //                                        return (
                          //                                            <div
                          //                                                key={index}
                          //                                                {...getSuggestionItemProps(suggestion, {
                          //                                                    className,
                          //                                                })}
                          //                                            >
                          //                                                {
                          //                                                    //<img
                          //                                                    //    src={require("../../assets/icon/location-pointer.svg")}
                          //                                                    //    alt="location-pointer"
                          //                                                    //    className="mr-2 map__suggestion-item--location-pointer"
                          //                                                    ///>
                          //                                                }
                          //                                                <span>{suggestion.description}</span>
                          //                                            </div>
                          //                                        );
                          //                                    })}
                          //                                </div>
                          //                            )}
                          //                        </div>
                          //                    )}
                          //            </PlacesAutocomplete>

                          //        </div>
                          //    </div>
                          //</div>
                      }
          </DialogContent>

          <DialogActions className="border-top">
            <Button
              type="submit"
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
          </Dialog>
          {
              isShowAddFile && (
                  <Dialog
                      onClose={onCloseSelectFile}
                      open={isShowAddFile}
                      fullWidth={true}
                      maxWidth="md"
                      className="dialog-preview-form"
                  >
                      <DialogTitle disableTypography>
                          <Typography variant="h6">Quản lý file</Typography>
                          <IconButton
                              aria-label="close"
                              className={classes.closeButton}
                              onClick={onCloseSelectFile}
                          >
                              <CloseIcon />
                          </IconButton>
                      </DialogTitle>
                      <DialogContent dividers>
                          <FileManagement
                              files={files}
                              setFiles={setFiles}
                              acceptedFiles={["jpeg", "png", "jpg", "gif","dwg","pdf","zip","rar"]}
                          />
                      </DialogContent>

                      <DialogActions>
                          <Button
                              type="button"
                              onClick={onCloseSelectFile}
                              variant="contained"
                              startIcon={<CloseIcon />}
                          >
                              Hủy
                                    </Button>
                          {files && files.length > 0 && (
                              <Button
                                  type="button"
                                  color="primary"
                                  variant="contained"
                                  startIcon={<SaveIcon />}
                                  onClick={onSaveSelectFile}
                              >
                                  Lưu
                              </Button>
                          )}
                      </DialogActions>
                  </Dialog>
              )
          }
    </div>
  );
}
